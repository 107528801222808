﻿

html,
body {
    height: calc(100% - 70px);
}

body i {
    font-style: normal;
    font-size: 23px;
    font-family: 'FontAwesome' !important;
}

/*html{
    height:100%
}*/
#pagename {
    margin: 135px auto 0;
    @media screen and (max-width:767px){
        margin: 90px auto 0
    }
    /*margin-bottom: 60px;*/
}


.notes {
    margin-left: 30px;
    margin-right: 30px;

    h6 {
        font-weight: 600;
        font-size: 14px;
        text-align: left;
        font: Bold 16px/20px Roboto;
        letter-spacing: 0;
        color: #333333;
        opacity: 1;
    }
}

.mrg-left {
    margin-left: 30px;
    margin-right: 30px;
}

#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.9);
    z-index: 111;
    cursor: pointer; 
}

.overlay-display-block {
    display: block !important;
}

body {
    background: white;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: rgb(74, 74, 74);
    background-attachment: fixed !important;
    overflow-y:scroll;
    /*margin-bottom:70px;*/
}

.body-content {
    position: relative;
    max-width: 1024px;
    padding: 35px 15px 30px 15px;
    margin: 0px auto 0;
    box-sizing: content-box;

    @media screen and (max-width: 1066px) {
        box-sizing: border-box;
        /*padding-bottom: 100px;*/
    }

    .back-to-top {
        display: inline-block;
        background-color: map-get($colors, primary-btns);
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 4px;
        position: fixed;
        bottom: 30px;
        left: 30px;
        transition: background-color .3s, opacity .5s, visibility .5s;
        opacity: 0;
        visibility: hidden;
        z-index: 1000;
    }

    .back-to-top::after {
        content: "\f077";
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        font-size: 2em;
        line-height: 50px;
        color: #fff;
    }

    .back-to-top:hover {
        cursor: pointer;
        background-color: map-get($colors, primary-btns);
        
    }

    .back-to-top:active {
        background-color: map-get($colors, primary-btns);
        
    }

    .back-to-top.show {
        opacity: 1;
        visibility: visible;
    }
}

.body-content-fixed {
    overflow: hidden;
}

.margin-mobile-body-content {
    max-width: 1024px;
    padding: 30px 15px;
    margin: 125.4px auto 0;
}

.body-content-home {
    max-width: 1024px;
    padding: 30px 5px;
    margin: 125.4px auto 0;
}

/*.pdf-download-btn-circle {
    text-align: center;
    padding: 7px;
    width: 81px;
    height: 81px;
    background-color: map-get($colors, primary-btns);
    top: 50%;
    left: 0%;
    position: fixed;
    color: white;
    cursor: pointer;


    img {
        width: 30px;
    }

    p {
        margin: 0px;
        text-transform: uppercase;
        font-size: 12px;
    }
}*/

.card-general {
    
    .container-img {
        @include padd-ver-hor(15px, 10px);

        .img-border-radius {
            width: 100%;
            height: 115px;
            object-fit: cover;
        }
    }

    .card-body-padding {
        line-height: 40px;
        width: 100%;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        padding: 0px;

        .title-category {
            display: inline-block;
            vertical-align: middle;
            font-family: OpenSans-Semibold;
            text-align: center;
            font-size: 1rem;
            line-height: 1;
            color: #4a4a4a;
            width: 100%;
            padding: 0 15px;
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .category-name {
            font-family: OpenSans-Semibold;
            text-align: center;
            font-size: 14px;
            line-height: 1;
            color: #4a4a4a;
            font-weight: 600;
            //
            text-align: center;
            font: Bold 14px/15px Roboto;
            letter-spacing: 0;
            color: #333333;
            opacity: 1;
        }
    }
}

.card-general:hover {
    transform: scale(1.05);
    transition-duration: 0.5s;
    cursor: pointer;

    .card-body-padding {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}


button,
.button {
    cursor: pointer;
}

.primary-btn {
    background: map-get($colors, primary-btns);
    border-radius: 18px;
    border: none;
    padding: 5px 40px;
    color: white;
    cursor: pointer;
}


.footer-style {
    width: 100%;
    height: 70px;
    text-align: center;
    border-top: 1px solid #707070;
    /*position: absolute;
    bottom: 0;*/
    > p {
        margin-top: 10px;
    }
}

@media screen and (min-width: 1025px) {
    .download-pdf,
    .download-exc {
        display: none;
    }
}

@media screen and (max-width: 767px) {

    .pdf-download-btn-circle,
    .exc-download-btn-circle {
        cursor: pointer;
        font-family: OpenSans-Semibold;
        text-align: left;
        line-height: 30px;
        width: 40px;
        height: 40px;
        background-image: url(../../../../images/navbar/download.png);
        background-size: 21px;
        background-repeat: no-repeat;
        background-position: 9px center;
        margin-right: 0;
        vertical-align: middle;
        display: inline-block;
        border: 1px solid #04A4EB;
        border-radius: 28px;
        opacity: 1;
    }

    .container-dwn-pdf,
    .container-dwn-exc {
        display: none;
    }
}

@media screen and (max-width: 372px) {

    .pdf-download-btn-circle,
    .exc-download-btn-circle {
        p {
            font-size: 16px;
            display: inline-block;
        }
    }
}

@media screen and (min-width: 767px){
    .mobile-device-searchbox{
        display:none;
    }
    /*.filter-partial{
        display:none;
    }*/
}
@media screen and (max-width: 767px) {
    .mobile-device-searchbox {
        margin: 0;
        justify-content: space-between;
        margin-left: 15px;
        margin-right: 15px;
    }
}


.download-pdf,
.download-exc{
    display: inline-block;
}

.color-btn-email-send {
    background-color: map-get($colors, primary-btns);
    border-color: map-get($colors, primary-btns);
}

.main-container {
    min-height: calc(100vh + 45px);
    margin-bottom: 70px;
}