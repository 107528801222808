@font-face {
    font-family: 'OpenSans-Light';
    src: url('../fonts/opensans-light-webfont.woff2') format('woff2'), 
        url('../fonts/opensans-light-webfont.woff') format('woff'),
        url('../fonts/opensans-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'OpenSans-Semibold';
    src: url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff'),
         url('../fonts/opensans-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
	font-family: 'KarbonLight';
    src: url('../fonts/karbon-light-webfont.woff2') format('woff2'),
		url('../fonts/karbon-light-webfont.woff') format('woff'),
		url('../fonts/karbon-light-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KarbonRegular';
    src: url('../fonts/karbon-regular-webfont.woff2') format('woff2'),
		url('../fonts/karbon-regular-webfont.woff') format('woff'),
		url('../fonts/karbon-regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KarbonMedium';
    src: url('../fonts/karbon-medium-webfont.woff2') format('woff2'),
		url('../fonts/karbon-medium-webfont.woff') format('woff'),
		url('../fonts/karbon-medium-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KarbonSemiBold';
    src: url('../fonts/karbon-semibold-webfont.woff2') format('woff2'),
		url('../fonts/karbon-semibold-webfont.woff') format('woff'),
		url('../fonts/karbon-semibold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KarbonBold';
    src: url('../fonts/karbon-bold-webfont.woff2') format('woff2'),
		url('../fonts/karbon-bold-webfont.woff') format('woff'),
		url('../fonts/karbon-bold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


