﻿.selectric-hide-select + .selectric {
    display: none !important;
}



.selectric-items {
    width: 50% !important;
    height: auto !important;
    right: 0;
    left: inherit;

    li {
        font-size: 14px;
        
            text-transform:capitalize;
        
    }
}

.ico {
    background-size: 28px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    height: 30px;
    width: 30px;
    margin: 0 6px 0 0;


/*    &.ico-it-IT {
        background-image: url(../../../../images/flags/it-IT-circle.png);
    }*/

    &.ico-it-IT {
        background-image: url(../../../../images/flags/map.png);
    }

    &.ico-en-GB {
        background-image: url(../../../../images/flags/en-GB-circle.png);
    }

    &.ico-es-ES {
        background-image: url(../../../../images/flags/es-ES-circle.png);
    }
}


ul {
    text-align: left;
}
