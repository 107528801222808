﻿
.btn-light {
    color: #212529 !important;
    background-color: transparent !important;
    /* border-color: #d3d9df; */
    border-radius: 50px !important;
}
.dropdown-toggle.bs-placeholder {
    color: black !important;
    background-color: transparent !important;
    border-radius: 50px !important;
}