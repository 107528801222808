﻿.sharing-partial {
    input.form-control:-webkit-autofill,
    input.form-control:-webkit-autofill:hover,
    input.form-control:-webkit-autofill:focus,
    input.form-control:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }
}




.sharing-partial {
    position: fixed;
    color: map-get($colors, text);
    z-index: 99999;
    top: 5%;
    left: 30%;
    width: 40%;
    height: fit-content;
    background: white;
    align-items: center;
    flex-flow: row wrap;
    border: 3px solid map-get($colors, primary-btns);

    .container-header-shareP {
        height: auto;
        position: relative;
        margin: 0;
        display: flex;
        padding: 5px;
        top: 20px;

        .under-container-shareP-dx {
            .close-btn {
                text-align: right;
                color: map-get($colors, text);

                .img-close-btn {
                    cursor: pointer;
                    max-width: 110px;
                    margin: 0px 10px 0px auto;
                }
            }
        }
    }

    .wrap-share-field {
        padding: 15px;


        .share-div-of-label,
        .share-div-of-label-messagebox {
            margin-bottom: 30px;

            .share-label {
                color: map-get($colors, text);
                margin: 0;
                display: block;
                opacity: 1;
                font-size: 16px;
                padding: 0 0 10px 10px;
            }

            .form-group {
                box-sizing: border-box;
                width: 100%;
                min-height: 44px;
                margin: 0px;
                padding: 8px;
                border: 1px solid map-get($colors, border-input);
                font-family: inherit;
                font-size: 1rem;
                font-weight: normal;
                color: map-get($colors, text);
                background: transparent;
                border-radius: 10px;
                box-shadow: none;
            }

            .message-text-share {
                height: 100px;
            }
        }

        .type-error-message {
            margin-top: -30px;
            margin-bottom: 25px;
            color: red;
        }
    }


    .share-div-of-label-privacy {
        display: flex;

        .btn-privacy {
            width: auto;
            margin-top: 5px;
        }

        .share-label-margin {
            margin-left: 10px;
        }

        .share-label {
            font-size: 16px;
            color: map-get($colors, text);
        }
    }

    .share-btn-nav {
        margin-bottom: 15px;
        width: 100%;
        text-align: center;
    }

    #privacy-error-message {
        color: red;
        bottom: 20px;
        .type-error-message {
            margin-top: -15px;
        }
    }

    #message-error-message {
        color: red;

        .type-error-message {
            margin-top: 0;
        }
    }
}


@media screen and (max-width: 767px) {
    .sharing-partial {
        overflow-y:scroll;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100vh;
        
    }
}

@media screen and (max-height: 900px) {
    .sharing-partial {
        top: 30%;
        bottom: 30%;
    }
    .sharing-partial-email-send {
        overflow-y: unset;
        top: auto;
        bottom: auto;
    }
}
.text-light {
    color: map-get($colors, text) !important;
}