﻿//IE

@media all and (-ms-high-contrast:none) {

    *::-ms-backdrop, .search-box-write {
        line-height: 15px !important;
    }
    /**::-ms-backdrop, .btn-search {
        background-size: 30px !important;
        background-position: 9px center !important;
    }*/
    /**::-ms-backdrop, .contact-logo-text {
        background-position: -30px center !important;
        background-size: 105px !important;
    }*/


    /* IE11 */
}

.alert {
    display: flex;
    justify-content: space-between;
}

.container-alert-width-d {
    position: absolute;
    width: 70%;
    top: 45px;
    left: 40px;

}

@media screen and (max-width: 767px) {
    .container-alert-width-m {
        position: relative;
        width: 70%;
        top: 15px;
        left: 0px;
    }
}

@media screen and (max-width: 767px) {
    .alert-d,
    .container-alert-width-d {
        display: none;
    }
}


.container-all {
    height: 100%;
    display: flex;
    width: 100%;
    /*padding: 0px 15px;*/
}

.navbar-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    color: #ebedec;
    /*background-color: white;*/
    padding: 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px 2px #DCDCDC;
}


.logo-content-home {
    padding: 0px;

    .menu-white {
        display: none;

        .menu-line-white {
            display: none;
        }
    }
}

.search-box-container {
    padding: 0px;
    margin-top: 33px;
    text-align: right;
}

.navbar-content .container-logos {
    max-width: 1024px;
    background: white;
    background-repeat: no-repeat;
    background-position: top center;
    height: 90px;
    padding: 0px;
    margin: 0 auto;
    margin-bottom: 1px solid #04A4EB;
}

.search-box {
    display: inline;
}

.flag-drop {
    width: 27px;
    cursor: pointer;
}



.search-box-write {
    width: calc(100% - 300px);
    height: 30px;
    padding: 10px;
    font-size: 14px;
    line-height: 30px;
    background-color: #d7d7d7;
    border-bottom-left-radius: 33px;
    border-top-left-radius: 33px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 0.63;
}



.container-search-btn {
    border-radius: 15px;
    margin-left: -4px;
    background-color: #ffffff;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    min-width: 0px;
    width: 50px !important;
}

#selectLanguage {
    position: absolute;
    top: 45px;
    right: 0;
    width: 360px;
}


.btn-search {
    width: 100%;
    height: 30px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #04A4EB;
    color: white;
    font-family: OpenSans-SemiBold;
    font-size: 11px;
    border: none;
    outline: none;
    background-image: url(../../../../images/navbar/icon_search.png);
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: 17px center;
}


.container-contacts,
.container-dwn-pdf,
.container-dwn-exc {
    min-width: 0px;
    vertical-align: middle;
    width: auto;
    height: 30px;
    display: inline-block;
}


.contact-logo-text {
    font-family: OpenSans-Semibold;
    text-align: left;
    line-height: 30px;
    width: 30px;
    height: 30px;
    color: #4a4a4a;
    background-color: #ffffff;
    background-image: url(../../../../images/navbar/email.png);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: 5px;
    margin-right: 0;
    margin-left: 10px;
    vertical-align: middle;
    display: inline-block;
    /*background: transparent url('img/email.png') 0% 0% no-repeat padding-box;*/
    border: 1px solid #04A4EB;
    border-radius: 18px;
    opacity: 1;
}

.contact-pdf-download,
.contact-exc-download {
    font-family: OpenSans-Semibold;
    text-align: left;
    line-height: 30px;
    width: 30px;
    height: 30px;
    color: #4a4a4a;
    background-color: #ffffff;
    background-image: url(../../../../images/navbar/download.png);
    background-repeat: no-repeat;
    background-position: 6px;
    margin-right: 0;
    margin-left: 10px;
    vertical-align: middle;
    display: inline-block;
    border: 1px solid #04A4EB;
    border-radius: 18px;
    opacity: 1;
}

.contact-decoration:hover,
.contact-pdf-download,
.contact-exc-download{
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    background-color: transparent;
}



.container-lang {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;

    .lang-en {
        margin: 0px auto;
        display: inline;
        bottom: 5px;
        right: 5px;
        width: 25px;
    }

    .lang-it {
        margin: 0px auto;
        display: inline;
        bottom: 5px;
        right: 5px;
        width: 25px;
    }
}

.logo-container {
    height: 100%;
}

.position-slider {
    display: inline-block;
    position: relative;
    top: 30%;
    float: right;

    .slider-change-page {
        width: 120px;
        display: inline-block;
        margin-left: 15px;
        border: 1px solid transparent;

        p {
            font-size: 15px;
            text-transform: uppercase;
            color: black;
            text-align: center;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

.slider-change-page:hover,
.IsActive {
    border-radius: 33px;
    border: 1px solid black !important;
    font-weight: bold;
    color: black;
    text-align: center;
    text-decoration: none;
}



.img-logo-amilon {
    max-width: 90px;
    margin: 20px 0px 15px 0;
}

.logo-amilon {
    padding: 0px;
    height: 100%;
    margin-right: 0;
}



.under-navbar {
    height: 45px;
    background-color: white;
    position: fixed;
    z-index: 10;
    top: 90px;
    left: 0;
    width: 100%;
    background: #333333 0% 0% no-repeat padding-box !important;
    opacity: 1;
}

.under-navbar-content {
    position: relative;
    font-size: 0;
    line-height: 55px;
    height: 55px;
    max-width: 1024px;
    height: 100%;
    /*padding: 0 15px;*/
    margin: 0 auto;
}

.all-categories-title {
    height: 45px;
    display: inline-block;
    //
    .title {
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        color: #FFFFFF;
        opacity: 1;
    }
}

.container-btn-filter {
    margin-top: 20px;
    text-align:center;

    .container-filter-mobile {
        /*margin-right: 30px;*/
        display: inline-block;

        @media screen and (max-width: 337px) {
            /*margin-right: 20px*/
        }

        .btn-filter {
            cursor: pointer;
            font-family: OpenSans-Semibold;
            text-align: left;
            line-height: 30px;
            width: 40px;
            height: 40px;
            color: #4a4a4a;
            background-color: #ffffff;
            background-image: url(../../../../images/retailer/filterlogo-blue.png);
            background-size: 21px;
            background-repeat: no-repeat;
            background-position: 9px center;
            margin-right: 0;
            vertical-align: middle;
            display: inline-block;
            border: 1px solid #04A4EB;
            border-radius: 28px;
            opacity: 1;

            p {
                display: inline-block;
                margin: 0 0 10px;
            }

            .style-count {
                border: 1px solid map-get($colors, primary-btns);
                display: inline-block;
                position: relative;
                position: relative;
                bottom: 1px;
                right: 36px;
                width: 30px;
                height: 30px;
                color: white;
                border-left: 0;
                /*background: url(../../../../images/retailer/filterlogo-white.png) no-repeat #04A4EB;*/
                background-position: center;
                background-size: 20px;
                font-family: OpenSans-Extrabold;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;

                @media screen and (max-width: 321px) {
                    left: initial;
                    right: -11px;
                }

                @media screen and (max-width: 541px) {
                    left: initial;
                    right: 32px;
                }
            }
        }
    }
}

.loc-filter-btn {
    vertical-align: top;
}

.menu {
    min-width: 0px;
    height: 45px;
    width: 85px;
    display: inline-block;

    @media screen and (max-width: 1070px) {
        margin-left: 15px;
    }
}

.menu-line {
    max-width: 35px;
    margin: 0 !important;
    cursor: pointer;
}

.title {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}




.digital-catalogue {
    display: inline-block;
    width: auto;
    min-width: 0px;
    font-size: 100em;
    position: relative;
    top: 20px;
}

.digital-catalogue > h2 > b {
    font-size: 22px;
    margin-bottom: 12px;
    font-family: Roboto;
    //
    text-align: left;
    font-weight: bold;
    letter-spacing: 0;
    color: #333333;
    opacity: 1;
}

.img-close-btn-category-p {
    margin-left: 10px;
    cursor: pointer;
}


.share-btn-nav {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;

    .share-btn {
        cursor: pointer;
        font-family: OpenSans-Semibold;
        text-align: left;
        line-height: 30px;
        width: 30px;
        height: 30px;
        color: #4a4a4a;
        background-color: #ffffff;
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: 6px center;
        margin-right: 0;
        vertical-align: middle;
        display: inline-block;
        /* background: transparent url(img/email.png) 0% 0% no-repeat padding-box; */
        border: 1px solid #04A4EB;
        border-radius: 18px;
        opacity: 1;
        background-image: url(../../../../images/navbar/share-btn.png);
    }
}

button:focus {
    outline: 0px;
}



@media screen and (min-width: 732px) {
    .btn-navbar-toggle {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .position-slider {
        margin: 0;

        .slider-change-page {
            width: 120px;
            margin-left: 0;

            > p {
                font-size: 15px;
            }
        }
    }

    .search-box-write {
        width: calc(100% - 290px);
    }
}

@media screen and (max-width: 1066px) {
    .container-all {
        padding: 0px 15px;
    }
}

@media screen and (max-width: 854px) {
    .digital-catalogue > h2 > b {
        font-size: 18px;
    }

    .container-all {
        padding: 0px 15px;
    }
}

@media screen and (max-width: 576px) {
    .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
}


@media screen and (max-width: 767px) {

    .position-slider {
        display: none;
    }

    .logo-container > .digital-catalogue {
        display: inline-block;
        width: auto;
        min-width: 0px;
    }

    .digital-catalogue > h2 > b {
        font-size: 1.0rem;
    }


    .container-logos {
        .logo-content-home {
            .menu-white {
                display: none;

                .menu-line-white {
                    display: block;
                    margin: 34px 0px 15px 0px !important;
                    width: 25px;
                    cursor: pointer;
                }
            }
        }
    }



    .search-box-container {
        position: absolute;
        right: 15px;
        display: block;

        .flag-drop {
            width: 41px;
            height: 41px;
            cursor: pointer;
        }

        .share-btn-nav {
            display: none;
        }
    }

    .btn-navbar-toggle {
        display: none;
    }

    .container-contacts {
        display: none !important;
    }

    .menu-mobile {
        min-width: 0px;
        height: 45px;
        margin-left: 10px;
        width: 55px;
        display: inline-block;
    }

    .search-box {
        display: none !important;
    }


    .img-logo-amilon {
        max-width: 100px;
        margin: 15px 10px 15px 0;
    }

    .under-navbar {
        display: block;
        height: 56.2px !important;
        background-color: white;
        background: #333333 0% 0% no-repeat padding-box;
        opacity: 1;

        .menu {
            display: none;
        }
    }



    .title {
        color: #4a4a4a;
        display: inline-block !important;
        vertical-align: middle;
        font-family: OpenSans-Semibold;
        font-size: 1.357rem;
        margin-left: 15px;
    }

    .navbar-content {
        background-image: none;
    }



    .padding-all-giftcards-categories,
    .padding-catalogue-download,
    .all-giftcards-categories {
        border-radius: 15px;
    }


    .container-btn-filter {
        .container-filter-mobile {
            .btn-filter {
                color: #04A4EB;
                background-color: white;
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .menu {
        width: 50px;
    }

    .all-categories-title {
        .title {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 437px) {
    .digital-catalogue > h2 > b {
        font-size: 15px;
    }

    .img-logo-amilon {
        margin: 25px 10px 15px 0;
    }

    .digital-catalogue {
        display: none !important;
    }
}

@media screen and (max-width: 380px) {
    .title {
        font-size: 16px;
    }

    .digital-catalogue {
        display: none !important;
    }
}

@media screen and (max-width: 370px) {
    .all-categories-title .title {
        font-size: 12px;
    }


    .menu {
        width: 40px;

        .menu-line {
            max-width: 25px;
        }
    }

    .img-logo-amilon {
        max-width: 70px;
        margin: 30px 0px 15px 0;
    }

    .share-btn-nav {
        .share-btn {
            font-size: 10px;
        }
    }

    .flag-drop {
        width: 20px;
    }

    .digital-catalogue {
        display: none !important;
    }
}
