﻿.single-retailer-body {
    background: white;
    opacity: 1;

    .body-content-retailer {
        background: white;
        max-width: 1024px;
        margin: 165.4px auto 0;


        .container-left-retailer {
            height: 100%;
            padding: 0 25px 0 30px;
            margin-top: 20px;

            .padd-container-left-retailer {
                height: 100%;
                margin-left: 7px;

                .container-retailer-img {
                    width: 145px;
                    height: 85px;
                    background-color: #ffffff;
                    border-radius: 15px;

                    .under-container-retailer-img {
                        height: 100%;

                        .single-retailer-img {
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 15px;
                        }
                    }
                }

                .gift-card-title {
                    font-family: OpenSans-Semibold;
                    font-size: 1rem;

                    .gift-card {
                        text-align: left;
                        font-family: Roboto;
                        font-size: 16px;
                        line-height: 19px;
                        letter-spacing: 0;
                        color: #333333;
                    }
                }


                .title-retailer-name {
                    text-transform: uppercase;
                    margin-top: 0;
                    margin-bottom: 20px;
                    //
                    text-align: left;
                    font: Bold 25px/30px Roboto;
                    letter-spacing: 0;
                    color: #04A4EB;
                    opacity: 1;
                }

                .country-flag-icon {
                    height: 20px;
                }



                .btn-category-name {
                    .title-category-name {
                        color: white;
                        display: inline-block;
                        font-size: 16px;
                        line-height: 19px;
                        padding: 7px 15px;
                        border-radius: 10px;
                        margin: 0;
                        background-color: #04A4EB;
                        //
                        background: #04A4EB 0% 0% no-repeat padding-box;
                        border-radius: 22px;
                        opacity: 1;
                    }
                }

                .share-link-page {
                    display: inline-block;

                    .share-link-page-title {
                        margin: 30px 0 0px 0;
                        font-family: Roboto;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: bold;
                        //
                        text-align: left;
                        letter-spacing: 0;
                        color: #333333;
                        opacity: 1;
                        text-transform: uppercase;
                    }

                    .share-btn-nav-single-retailer {
                        display: inline-block;
                        vertical-align: middle;
                        margin-top: 5px;

                        .share-btn-single-retailer {
                            cursor: pointer;
                            font-family: OpenSans-Semibold;
                            text-align: left;
                            line-height: 30px;
                            width: 30px;
                            height: 30px;
                            color: #4a4a4a;
                            background-color: #ffffff;
                            background-size: 15px;
                            background-repeat: no-repeat;
                            background-position: 6px center;
                            margin-right: 0;
                            vertical-align: middle;
                            display: inline-block;
                            /* background: transparent url(img/email.png) 0% 0% no-repeat padding-box; */
                            border: 1px solid #04A4EB;
                            border-radius: 18px;
                            opacity: 1;
                            background-image: url(../../../../images/navbar/share-btn.png);
                        }
                    }
                }

                .short-description {
                    margin-top: 30px;
                    text-align: left;
                    font-family: Roboto;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0;
                    color: #333333;
                    opacity: 1;
                }

                .change-lang {
                    margin-top: 10px;
                    cursor: pointer;
                    display: inline-block;
                }

                .amounts-title {
                    margin: 30px 0 20px 0;
                    font-family: Roboto;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: bold;
                    //
                    text-align: left;
                    letter-spacing: 0;
                    color: #333333;
                    opacity: 1;
                }

                .amount-currency {
                    max-height: 200px;
                    overflow: hidden;
                    margin: 0 -5px;
                    transition: max-height .5s;

                    .single-amount-currency {
                        height: auto;
                        width: auto;
                        display: inline-block;
                    }

                    .box-currency {
                        font-family: OpenSans-Semibold;
                        font-size: 0.8rem;
                        text-align: center;
                        padding: 7px 0;
                        margin: 5px;
                        font-weight: bold;
                        //
                        background: #333333 0% 0% no-repeat padding-box;
                        border-radius: 22px;
                        opacity: 1;
                        color: white;
                        width: 98px;
                        height: 36px;
                    }
                }

                .amount-from-to {
                    .label-amount-from-to {
                    }
                }

                .show-countries {
                    margin-top: 20px;

                    .container-link-change-lang {
                        display: inline-block;

                        .change-country {
                            color: black;
                            text-decoration: underline;
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        .delete-margin {
            padding: 0px;

            .container-right-retailer {
                padding: 0px 50px 50px 25px;
                border-left: 1px solid #04A4EB;
                margin-top: 20px;
                margin-bottom: 20px;

                .wrap-content-right-retailer {
                    height: auto;

                    .container-img-retailer-bottom {
                        padding: 0 0 30px;

                        .container-img-retailer-size {
                            .img-retailer-size {
                                display: block;
                                margin: 0 auto;
                                max-width: 80%;
                                border: 1px solid #707070;
                                border-radius: 20px;
                                opacity: 1;
                            }
                        }
                    }
                }

                .gift-card-details {

                    .gift-card-details-title > h6 {
                        margin-top: 30px;
                        margin-bottom: 20px;
                        font-weight: 600;
                        font-size: 14px;
                        //
                        text-align: left;
                        font: Bold 16px/20px Roboto;
                        letter-spacing: 0;
                        color: #333333;
                        opacity: 1;
                    }

                    .margin-box {
                        margin: 10px 0;

                        .under-margin-box {
                            height: auto;

                            .icon {
                                display: inline-block;

                                .img-icon-validity {
                                    background-image: url(../../../../images/pages/icone_validity.svg);
                                }

                                .img-icon {
                                    background-image: url(../../../../images/pages/icon_check.png);
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    margin: 0 10px 0 20px;
                                }

                                .img-icon-else {
                                    background-image: url(../../../../images/pages/icon_close_white.png);
                                    border: 1px solid #333333;
                                    opacity: 1;
                                }

                                .img-icon-online {
                                    background-image: url(../../../../images/pages/icon_check.png);
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    margin: 0 10px 0 20px;
                                }

                                .img-icon-offline {
                                    background-image: url(../../../../images/pages/icone_store.svg);
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    margin: 0 10px 0 20px;
                                }
                            }




                            .details-title {
                                display: inline-block;
                            }

                            .details-title > h6 {
                                margin-top: 2px;
                                margin-bottom: 0px;
                                font-size: 14px;
                                margin-bottom: 0px;
                                font-weight: 500;
                                line-height: 1.1;
                                color: inherit;
                                //
                                text-align: left;
                                font: 16px/20px Roboto;
                                letter-spacing: 0;
                                color: #333333;
                                opacity: 1;
                            }
                        }
                    }
                }

                .where-to-reedem {
                    .where-to-reedem-title > h6 {
                        margin-top: 30px;
                        margin-bottom: 20px;
                        font-weight: 600;
                        font-size: 14px;
                        //
                        text-align: left;
                        font: Bold 16px/20px Roboto;
                        letter-spacing: 0;
                        color: #333333;
                        opacity: 1;
                    }
                }

                .notes {
                    .notes-title > h6 {
                        margin-top: 30px;
                        margin-bottom: 10px;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 767px) {
    .single-retailer-body {

        .body-content-retailer {
            margin: 145px auto 0 !important;
            padding-bottom: 100px;

            .container-left-retailer {
                padding: 0;

                .padd-container-left-retailer {
                    padding: 20px 15px 10px;
                }
            }

            .delete-margin {
                .container-right-retailer {
                    padding: 15px;
                    padding-top: 50px;
                    border: 0;
                }
            }

            .delete-margin-sr {
                .container-right-retailer {
                    .wrap-content-right-retailer {
                        .container-img-retailer-bottom {
                            .container-img-retailer-size {
                                .img-retailer-size {
                                    display: block;
                                    max-width: 80%;
                                    border: 1px solid #707070;
                                    border-radius: 30px;
                                    opacity: 1;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .delete-margin-sr {
        margin: 0;
    }
}
