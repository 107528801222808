﻿.tooltip-inner {
    background-color: white;
    border: 1px solid map-get($colors, primary-btns);
    color: map-get($colors, primary-btns);

    
}
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: map-get($colors, primary-btns);
}

.tooltip.bs-tooltip-bottom.show {
}