﻿$colors: (
    black: #000000, 
    grey: #808080, 
    white: #ffffff,
    red: #ff0000,
    green: #00ff00,
    blue: #0000ff,
    yellow: #ffff00,
    ciao: linear-gradient(to right, #E1E1E1, #F7F7F7),
    shadow:  0px 5px 10px 0px rgba(74,74,74,0.6),
    shadow-sp: rgba(74, 74, 74, 0.6) 0px 8px 50px -5px,
    shadow2: rgba(163, 178, 174, 1) 0px 0px 5px 0px,
    box-shadow-hover: 0px 10px 30px -5px rgba(74,74,74,0.6),
    bgc-hover-ciano: #b7dcfc,


    // HOVER COLORS AND COLOR OF 2PX SOLID LINE :
    
    hover-light-rose: #e6dee9,
    hover-rose: #f2d3e7,
    hover-grey: #c1c1c1,
    hover-azure-light: #b7dcfc,
    hover-azure-acquamarine: #72d3c1,
    hover-orange: #ff9676,
    hover-azure: #9dc7d6,
    hover-green: #b4f18e,
    hover-sand: #f2d9b1,
    hover-pink: #ffcad2,
    hover-light-orange: #ffa697,
    hover-light-grey: #e1eaef,
    hover-light-green: #d3eade,
    hover-yellow: #f2f09c,
    hover-light-yellow: #fddb92,

    //new UI colors

    primary-btns: #04A4EB,
    border-input: #707070,
    text: #333333

    ) !default;
