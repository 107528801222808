﻿
body {
    background: transparent linear-gradient(177deg, #5BC0CA 0%, #086F92 62%, #D73E6E 100%) 0% 0% no-repeat padding-box;
}

.contacts-container {
    position: relative;

    .under-contacts-container {
        height: auto;

        .contacts-container-margin {
            /*height: calc(100vh - 70.4px);
            padding-top: 40px;
            margin-top: 70.4px;*/
            color: white;

            .align-items-inside {
                max-width: 1024px;
                display: flex;
                /*margin: auto !important;*/
                background-color: white;
                padding: 10px;
                color: black;
                font-family: Roboto !important;



                .wrap-contact {
                    .under-register {
                        margin-top: 10px;

                        .title-container {
                            font-size: 26px;
                            text-transform: uppercase;
                            padding-left: 15px;
                        }

                        .wrap-hr-style {
                            padding-left: 15px;

                            .hr-line-sx {
                                width: 100px;
                                border-top-width: 4.5px;
                                border-top-style: solid;
                                border-top-color: #04A4EB;
                                margin: 0 auto 40px 0;
                            }
                        }


                        .sx {
                            margin-top: 40px;

                            .column-contact {
                                display: flex;
                            }

                            .form-group {


                                .text-error {
                                    color: red;
                                }

                                .align-check {
                                    position: relative;
                                    top: 3px;
                                    left: 5px;
                                    width: auto;
                                    display: inline;
                                    margin-right: 20px;
                                    height: 13px;
                                }

                                .btn-sub {
                                    display: inline-block;
                                    font-size: 14px;
                                    text-align: left;
                                    line-height: 20px;
                                    height: 30px;
                                    color: white;
                                    background-color: #04A4EB;
                                    padding: 0 10px;
                                    border-bottom-left-radius: 15px;
                                    border-top-left-radius: 15px;
                                    border-bottom-right-radius: 15px;
                                    border-top-right-radius: 15px;
                                    margin: 0 auto 0 0;
                                    //
                                    background: #04A4EB;
                                    border-radius: 18px;
                                    border: none;
                                    padding: 5px 40px;
                                    color: white;
                                    cursor: pointer;
                                }
                            }

                            .padding-text-area {
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }
                }

                .contacts-width-container {
                    height: auto;
                    max-width: 1024px;
                    padding: 0px 30px;
                    margin: 0 auto;

                    .contacts-text-container {
                        height: auto;
                        margin-top: 10px;

                        .title-container {
                            position: relative;
                            margin-top: 20px;

                            h3 {
                                font-size: 15px;
                                margin-top: 20px;
                                margin-bottom: 10px;
                                font-weight: 500;
                                line-height: 1.1;
                                color: inherit;
                            }
                        }


                        .details {
                            position: relative;

                            h1 {
                                font-size: 26px;
                                text-transform: uppercase;
                                margin: 0 0 8px;
                                font-weight: 500;
                                line-height: 1.1;
                                color: inherit;
                            }
                        }


                        .hr-line {
                            position: relative;

                            hr {
                                width: 100px;
                                border-top-width: 4.5px;
                                border-top-style: solid;
                                border-top-color: #04A4EB;
                                margin: 0 auto 40px 0;
                            }
                        }


                        .place-order-title {
                            position: relative;

                            h4 {
                                font-size: 1.357rem;
                                margin: 0;
                                margin-bottom: 20px;
                            }
                        }

                        .email-container {
                            h6 {
                                margin-top: 10px;
                                font-size: 14px;
                                line-height: 25px;
                                margin-bottom: 40px;

                                .email-link {
                                    text-decoration: none;
                                    color: inherit;
                                    background-color: transparent;
                                }

                                .amilon-link {
                                    font-size: 14px;

                                    a {
                                        text-decoration: none;
                                        color: map-get($colors, primary-btns);
                                        background-color: transparent;
                                    }
                                }

                                .amilon-link-eu {
                                }
                            }
                        }

                        .directly-by-italy {
                            position: relative;

                            h4 {
                                font-size: 19px;
                                margin: 0;
                                margin-bottom: 20px;
                            }
                        }

                        .firm-visiters {
                            position: relative;

                            .firm-visiters-margin {
                                font-size: 14px;
                                line-height: 25px;
                                margin-bottom: 40px;
                            }
                        }

                        .spain-link {
                            position: relative;

                            h6 {
                                margin-top: 10px;
                                font-size: 14px;
                                line-height: 25px;
                                margin-bottom: 40px;

                                b {
                                }
                            }
                        }

                        .btn-homepage-container {
                            position: relative;

                            .btn-homepage {
                                display: inline-block;
                                font-size: 14px;
                                text-align: left;
                                line-height: 30px;
                                height: 30px;
                                color: white;
                                background-color: #04A4EB;
                                padding: 0 10px;
                                border-bottom-left-radius: 15px;
                                border-top-left-radius: 15px;
                                border-bottom-right-radius: 15px;
                                border-top-right-radius: 15px;
                                margin: 0 auto 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.decoration-giftcard-link,
.decoration-giftiamo-link,
.decoration-mitarjetaregalo-link,
.btn-homepage-decoration-link {
    text-decoration: none;
    color: inherit;
    background-color: transparent;
}

.decoration-giftcard-link:hover,
.decoration-giftiamo-link:hover,
.decoration-mitarjetaregalo-link:hover,
.btn-homepage-decoration-link:hover {
    text-decoration: none;
    color: inherit;
    background-color: transparent;
}

@media screen and (max-width: 1400px) {
    .contacts-container {
        .under-contacts-container {
            .contacts-container-margin {
                .align-items-inside {
                    /*padding: 0px;*/
                }
            }
        }
    }
}


@media screen and (max-width: 767px) {
    .contacts-container {
        .under-contacts-container {
            .contacts-container-margin {
                height: 100%;
                background: white;
                padding-top: 40px;
                margin-top: 50px;
                color: white;

                .align-items-inside {
                    display: block;
                    margin: 0 !important;

                    .wrap-contact {
                        .under-register {
                            .title-container {
                                .hr-line-sx {
                                    width: 100px;
                                    border-top-width: 4.5px;
                                    border-top-style: solid;
                                    border-top-color: #04A4EB;
                                    margin: 0 auto 40px 0;
                                }
                            }

                            .sx {
                                .column-contact {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .col-sm-5 {
        max-width: 100%;
    }
}
