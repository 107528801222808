﻿.wrap-filter {
    max-height: 1200px;
    overflow-y: hidden;
    -webkit-transition: max-height 0.3s ease-in-out;
    -moz-transition: max-height 0.3s ease-in-out;
    -o-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
    position: fixed;
    z-index: 10;
    left: 0;
    width: 100%;

    @media screen and (max-width:767px) {
        overflow-y: scroll;
        height: calc(100vh - 90px)
    }


    &.closed {
        max-height: 0px;
        -webkit-transition: max-height 0.3s ease-in-out;
        -moz-transition: max-height 0.3s ease-in-out;
        -o-transition: max-height 0.3s ease-in-out;
        transition: max-height 0.3s ease-in-out;
        position: fixed;
        z-index: 10;
        left: 0;
        width: 100%;
    }

    background-color: map-get($colors, white);

    .under-wrap-filter {
        height: auto;

        .margin-filter {
            max-width: 1024px;
            padding: 20px 30px 60px;
            margin: 0 auto;
            margin-bottom: 100px;

            .container-body-filter {
                height: auto;

                .select-filter {
                    .select-filter-margin {
                        font-family: OpenSans-Light;
                        font-size: 1.357rem;
                        margin-top: 30px;
                        padding-bottom: 30px;

                        @media screen and (max-height: 900px) {
                            margin-top: 0px;
                            margin-bottom: 30px;
                        }

                        span {
                            text-transform: uppercase;
                            font-weight: bold;
                        }

                        .search-box-filter-margin {
                            display: inline;
                            margin-left: 160px;

                            @media screen and (max-height: 900px) {
                                margin-left: 70px;
                            }

                            .search-box-filter {
                                width: calc(100% - 580px);
                                height: 30px;
                                padding: 10px;
                                font-size: 14px;
                                line-height: 28px;
                                background-color: #d7d7d7;
                                border-radius: 15px;
                                border: none;
                                outline: none;
                                display: inline-block;
                                vertical-align: middle;
                                background: #FFFFFF 0% 0% no-repeat padding-box;
                                border: 1px solid #707070;
                                opacity: 0.63;

                                @media screen and (max-height: 900px) {
                                    width: calc(100% - 480px);
                                }
                            }
                        }
                    }
                }

                .container-option-filter {
                    height: 200px;
                    margin: 0;

                    @media screen and (max-width:767px) {
                        height: auto;
                    }

                    .under-container-option-filter {
                        height: 100%;
                        margin: 0;
                        display: flex;

                        @media screen and (max-width:767px) {
                            display: block;
                            height: auto;
                        }

                        .left-column {
                            padding: 0 30px;
                            border-left: solid 1px #04A4EB;
                            height: 100%;

                            @media screen and (max-width:767px) {
                                margin-bottom: 20px;
                                border-bottom: solid 1px #e0e0e0;
                                border-left: 0;
                                padding: 0;
                                margin-top: 20px;
                            }

                            .container-option-margin {
                                margin-bottom: 20px;
                                font-size: 0;

                                .container-btn-text {
                                    .under-container-btn-text {
                                        display: inline-block;
                                        position: relative;
                                        width: auto;
                                        font-weight: normal;
                                        font-size: 14px;
                                        padding-left: 35px;
                                        padding-right: 0;
                                        cursor: pointer;
                                        user-select: none;
                                        margin: 0px;

                                        .container-text {
                                            font-weight: normal;
                                            font-size: 14px;
                                            user-select: none;
                                            cursor: pointer;
                                        }
                                    }

                                    .input-btn {
                                        position: absolute;
                                        opacity: 0;
                                        margin: 4px 0 0;
                                        line-height: normal;
                                    }

                                    .checkmark {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        height: 23px;
                                        width: 23px;
                                        border: solid 1px #e7e7e7;
                                        background-color: #ffffff;
                                    }

                                    input:checked ~ .checkmark {
                                        background-color: #04A4EB;
                                        background-image: url(../../../../images/components/icon_check_white.svg);
                                        color: #ffffff;
                                        background-size: 70%;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                    }
                                }
                            }
                        }

                        .center-column {
                            padding: 0 30px;
                            border-left: solid 1px #04A4EB;
                            height: 100%;

                            @media screen and (max-width:767px) {
                                margin-bottom: 20px;
                                border-bottom: solid 1.5px #e0e0e0;
                                border-left: 0;
                                padding: 0;
                                max-width: 100% !important;
                            }

                            .title-margin {
                                margin-bottom: 20px;
                                position: relative;

                                h5 {
                                }
                            }

                            .container-option-margin {
                                margin-bottom: 20px;
                                font-size: 0;

                                .container-btn-text {
                                    .under-container-btn-text {
                                        display: inline-block;
                                        position: relative;
                                        width: auto;
                                        font-weight: normal;
                                        font-size: 14px;
                                        padding-left: 35px;
                                        padding-right: 0;
                                        cursor: pointer;
                                        user-select: none;
                                        margin: 0px;

                                        .container-text {
                                            font-weight: normal;
                                            font-size: 14px;
                                            user-select: none;
                                            cursor: pointer;
                                        }
                                    }

                                    .input-btn {
                                        position: absolute;
                                        opacity: 0;
                                        margin: 4px 0 0;
                                        line-height: normal;
                                    }

                                    .checkmark {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        height: 23px;
                                        width: 23px;
                                        border: solid 1px #e7e7e7;
                                        background-color: #ffffff;
                                    }

                                    input:checked ~ .checkmark {
                                        background-color: #04A4EB;
                                        background-image: url(../../../../images/components/icon_check_white.svg);
                                        color: #ffffff;
                                        background-size: 70%;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                    }
                                }
                            }
                        }

                        .right-column {
                            padding: 0 30px;
                            height: 100%;

                            @media screen and (max-width:767px) {
                                margin-bottom: 20px;
                                border-left: 0;
                                padding: 0;
                                max-width: 100%
                            }

                            .title-margin {
                                margin-bottom: 20px;
                                position: relative;

                                @media screen and (max-height: 900px) {
                                    margin-bottom: -15px;
                                }

                                h5 {
                                    font-family: OpenSans-Semibold;
                                    font-size: 14px;
                                    margin-top: 0;
                                    text-transform: uppercase;
                                    margin-bottom: 20px;
                                }
                            }

                            .container-option-dropdown {
                                margin-bottom: 30px;

                                .under-container-option-dropdown {
                                    font-size: 0;

                                    .input-dropbox {
                                        display: none;
                                        font-family: inherit;
                                        font-size: inherit;
                                        line-height: inherit;
                                    }

                                    .dropbox-select {
                                        width: 100%;
                                        font-family: OpenSans-Semibold;
                                        color: #ffffff;
                                        padding-top: 0;
                                        border: solid 2.5px #e7e7e7;
                                        outline: none;
                                        font-size: 14px;

                                        option {
                                            background: #ffffff;
                                            color: #4a4a4a;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .margin-top {
                    margin-top: 30px;
                }

                .country-title-margin {
                    margin-top: 15px;
                    position: relative;


                    h5 {
                        text-transform: capitalize;
                    }
                }

                .container-countries {
                    height: auto;

                    .country-select {
                        border: solid 1px black;
                        width: 100%;
                        border-radius: 50px;
                    }

                    .container-check-text {
                        display: inline-block;
                        width: 180px;
                        height: 40px;

                        .under-container-check-text {
                            display: inline-block;
                            position: relative;
                            width: auto;
                            font-weight: normal;
                            font-size: 14px;
                            padding-top: 2px;
                            padding-left: 35px;
                            padding-right: 0;
                            cursor: pointer;
                            user-select: none;

                            .input-btn {
                                position: absolute;
                                opacity: 0;
                                margin: 4px 0 0;
                                line-height: normal;
                            }

                            .checkmark {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 23px;
                                width: 23px;
                                border: solid 1.5px #e7e7e7;
                                background-color: #ffffff;
                            }

                            input:checked ~ .checkmark {
                                background-color: #46c37e;
                                background-image: url(../../../../images/components/icon_check_white.svg);
                                color: #ffffff;
                                background-size: 70%;
                                background-position: center;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }

                .container-category {
                    .category-select {
                        border: solid 1px black;
                        border-radius: 50px;
                        width: 100%;

                        .dropdown-toggle.bs-placeholder {
                            color: black;
                            background-color: transparent;
                            border-radius: 50px;
                        }
                    }
                }

                .container-contract {
                    .contract-select {
                        border: solid 1px black;
                        border-radius: 50px;
                        width: 100%;

                        .dropdown-toggle.bs-placeholder {
                            color: black;
                            background-color: transparent;
                            border-radius: 50px;
                        }
                    }
                }

                .reset-apply-btn-filter-container {
                    margin-top: 45px;
                    height: auto;

                    .under-reset-apply-btn-filter-container {
                        text-align: center;
                        direction: inherit;

                        .container-reset {
                            display: inline-block;
                            width: 30%;
                            height: 47px;
                            font-size: 16px;
                            line-height: 29px;
                            border-radius: 50px;
                            cursor: pointer;
                            max-width: none;
                            padding-right: 0;
                            user-select: none;
                            text-align: center;
                            background-color: white;
                            border: 1px solid #04A4EB;
                            color: #04A4EB;
                            text-transform: uppercase;
                        }

                        .container-apply {
                            color: #ffffff;
                            background-color: #04A4EB;
                            margin-left: 10px;
                            display: inline-block;
                            width: 30%;
                            height: 47px;
                            font-size: 16px;
                            line-height: 29px;
                            border-radius: 50px;
                            cursor: pointer;
                            max-width: none;
                            padding-right: 0;
                            user-select: none;
                            text-align: center;
                            border: 0;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

.mobile-device-style{
    
}

@media screen and (max-width:807px) {

    .wrap-filter {
        .under-wrap-filter {
            .margin-filter {
                .container-body-filter {
                    .select-filter {
                        .select-filter-margin {
                            display: flex;
                            justify-content: space-between;

                            .search-box-filter-margin {
                                display: block;
                                margin: auto;
                                margin-top: 20px;

                                .search-box-filter {
                                    width: 100%;
                                }
                            }

                            .close-btn-filter {
                                cursor: pointer;
                                display: inline-block;
                                background: url(../../../../images/shared/icon_close_black.png);
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }

                    .splitter-border {
                        border-bottom: 2px solid lightgray;
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px){
    .col-sm-5 {
        max-width: 100%;
    }
}
