﻿.language-container {
    position: fixed;
    top: 25%;
    left: 30%;
    width: 40%;
    height: auto;
    background: #4a4a4a;
    background-color: rgba(74,74,74,0.9);
    align-items: center;
    z-index: 112;

    .language-title {

        .language-retailer-name {
            text-align: center;
            font-size: 22px;
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: inherit;
            font-weight: 500;
            line-height: 1.1;
            color: white;
            margin-left:20%;
            margin-right:20%;
            word-break: break-word;
        }

        .language-select-country {
            font-size: 14px;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
            color: white;
        }
    }

    .close-btn {
        position: absolute;
        right: 20px;
        top: 20px;

        .img-close-btn {
            cursor: pointer;
            max-width: 110px;
            margin: 0px 0px 0px auto;
        }
    }

    .country-container {
        display: flex !important;
        width: 100%;
        height: 90%;
        margin-top: 0%;
        align-items: center;
        padding-bottom: 10px;
        padding-top: 10px;

        .margin-country-container {
            font-size: 0;
            max-width: 80%;
            margin: 0 auto;
            text-align: center;

            .container-single-country {
                display: inline-block;
                margin: 20px;

                .single-country {

                    .img-country {
                        margin: 0px auto;
                        max-width: 110px;
                    }

                    .name-country {
                        font-size: 14px;
                        text-align: center;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        color: white;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1023px) {
    .language-container {
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background: #4a4a4a;
        background-color: rgba(74,74,74,0.9);
        align-items: center;
    }
}

@media screen and (max-width: 767px) {
    .language-container {
        .country-container {
            height: auto;
        }
    }
    
}
