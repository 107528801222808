﻿.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
    
    background-color: transparent !important;
    color: black !important;
    
}

.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
    background-color: transparent !important;
    color: black !important;
}

.irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before {
    display:none !important;
}

.irs--round .irs-handle {
    top: 24px;
    width: 27px;
    height: 27px;
    background-color: map-get($colors, primary-btns) !important;
    border-radius: 24px;
    border: map-get($colors, primary-btns) !important;
    background-image: url(../../../../images/retailer/double-arrow.png) !important;
    background: no-repeat;
    background-position: center;
    cursor: pointer;

    @media screen and (max-width: 767px) {
        top: 20px !important;
        width: 37px !important;
        height: 37px !important;
        background-color: #04A4EB !important;
        border-radius: 24px;
        border: #04A4EB !important;
        background-image: url(../../../../images/retailer/double-arrow.png) !important;
        background: no-repeat;
        background-position: center;
    }
}
.irs--round .irs-bar {
    background-color: #C4C4C4;
}

.irs--round .irs-line {
    top: 42px;
    height: 4px;
    background-color: #C4C4C4;
    border-radius: 4px;
}
.irs {
    bottom: 6px !important;
}