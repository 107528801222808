﻿.body-single-category {
    font-size: 14px;

    .sp-img-proprieties-container {
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 40px;
        background-color: white;

        .sp-container-img {
            padding-left: 0px;
            padding-right: 50px;


            .sp-card {
                padding-right: 60px;
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                min-width: 0;
                word-wrap: break-word;
                background-color: transparent;
                background-clip: border-box;
                border: 0px;
                border-radius: .25rem;

                .sp-container-img-shadow {
                    box-shadow: map-get($colors, shadow-sp);
                }

                .card-img-top {
                    width: 97%;
                }
            }
        }
    }

    .sp-container-info {
        padding: 0px;

        .sp-container-text > h3 {
            font-family: OpenSans-Light;
            font-size: 1.071rem;
            line-height: 1.1;
            margin-bottom: 10px;
        }

        .sp-container-text > h3 > b {
            font-family: Roboto;
            font-size: 16px;
            line-height: 1.1;
            text-align: left;
            letter-spacing: 0;
            color: #333333;
            opacity: 1;
        }

        .sp-title {
            font-family: Roboto;
            font-size: 25px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.1;
            text-align: left;
            letter-spacing: 0;
            color: #04A4EB;
            opacity: 1;
        }

        .sp-hr {
            width: 100px;
            border-top-width: 4.5px;
            border-top-style: solid;
            margin: 0 auto 40px 0;
            border-top-color: map-get($colors, hover-light-rose);
        }

        .sp-description {
            font-size: 1.071rem;
        }
    }
}


.filter_left {
    width: 220px;
}

.flex_flow {
    margin-left: auto;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }
}

.placeholder-name-filter {
    width: 100%;
    
    .container-filter-selected {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        margin-bottom: 10px;
        margin-right: 10px;
        text-align: center;

        .filter-box-selected {
            background-color: map-get($colors, primary-btns);
            border-radius: 5px;
            padding: 5px;
            margin-right: 10px;
            margin-bottom: 10px;

            .filter-type,
            .filter-selected {
                display: inline-block;
                color: white;
                margin: 0;
            }

            .filter-type {
                text-transform: capitalize;
            }

            .remove-filter {
                display: inline-block;
                background: transparent url('../../../../images/retailer/delete-filter.png') 0% 0% no-repeat padding-box;
                width: 12px;
                height: 9px;
                transform: matrix(-1, 0, 0, -1, 0, 0);
                margin: 0;
                margin-left: 10px;
                cursor: pointer
            }
        }
    }
}


.sp-retailer-container-img {
    height: 192px;
    /*width: 220px;*/
    background-color: #ffffff;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 25px;
    cursor: pointer;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    text-align: center;
    position: relative;

    .select-lenguage {
        margin: 0px auto;
        display: inline;
        bottom: 5px;
        position: absolute;
        right: 5px;
        width: 25px;
    }
}

.retailer-img {
    max-width: 100%;
    border-radius:14px;
}

//plug-in range-slider

//
.dwnl-pdf {
    position: unset;
    right: 0;
    top: 0;

    .dwnl-text-align {
        text-align: center;

        .dwnl-center {
            display: inline-block;
            color: #ffffff;
            background-color: #4a4a4a;
            margin-left: 10px;
            width: 130px;
            height: 30px;
            font-size: 0.786rem;
            line-height: 29px;
            border: solid 1.5px #4a4a4a;
            border-radius: 15px;
            cursor: pointer;
            max-width: none;
            padding-right: 0;
            box-shadow: 0px 8px 20px -5px rgba(74, 74, 74, 0.5);
            user-select: none;
            text-align: center;
        }
    }
}



.sp-single-container-retailer {
    width: 20%;
    vertical-align: top;
    padding: 0;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    .inline-flag-name {
        position: absolute;
        width: 100%;
        left: 0;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        //
        font: 14px/15px Roboto;
        letter-spacing: 0;
        color: #333333;
        opacity: 1;
        text-transform: capitalize;
        margin-top: 20px;
        text-align: initial;
    }

    .country-flag-icon {
        display: block;
        margin-top: 53px;
        margin-left: 0;
        height: 20px;
    }
}

.sp-retailer-container-img:hover {
    border-width: 3px;
    transform: scale(1.05);
    text-decoration: none;

    .select-lenguage {
        margin: 0px auto;
        display: inline;
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 25px;
    }
}


.retailer-name {

    & > p {
        color: rgb(74, 74, 74);
        text-decoration: none;
        font-family: OpenSans-Semibold;
        font-size: 9.8px;
        text-align: center;
        text-transform: uppercase;
    }
}



.retailer-height {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    border-radius: 14px;
    overflow: hidden;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }
}

.back-to-catalogue,
.back-to-catalogue:hover {
    display: inline-block;
    color: map-get($colors, primary-btns);
    text-decoration: none;
}

@media screen and (max-width: 1023px) {
    .sp-single-container-retailer {
        width: 25%;

        .inline-flag-name {
            white-space: nowrap;
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 880px) {
    .sp-single-container-retailer {
        width: 25%;

        .inline-flag-name-single-category {
            white-space: nowrap;
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 28%;
        }
    }
}


@media screen and (max-width: 767px) {
    .sp-single-container-retailer {
        width: 33%;

        .inline-flag-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .filter_left{
        display:none;
    }

    .retailer-img {
        /*margin: 0 auto;*/
    }

    .body-single-category .sp-img-proprieties-container .sp-container-img {
        padding: 0;
        margin-bottom: 20px;
    }

    .placeholder-name-filter{
        display:none;
    }
}

@media screen and (max-width: 500px) {
    .sp-single-container-retailer {
        width: 50%;

        .inline-flag-name {
            white-space: nowrap;
            width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .filter_left {
        display: none;
    }

    .retailer-img {
        /*margin: 0 auto;*/
    }

    .body-single-category .sp-img-proprieties-container .sp-container-img {
        padding: 0;
        margin-bottom: 20px;
    }

    .placeholder-name-filter {
        display: none;
    }
}
