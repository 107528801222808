﻿.add-overflow {
    /*overflow-y: scroll;*/
    //height: 70%; //to be fixed
    /*height: calc(100vh - 245.4px);*/
}


.custom-radio .custom-control-label::before {
    border-radius: .25rem;
}

.custom-control, .custom-checkbox :hover {
    cursor: pointer;
}

.container-des-filter {
    height: auto;

    .filter-btn-container {
        border-bottom: 1px solid #B5B5B5;
        justify-content: space-between;
        display: flex;

        .filter-icon {
            background-image: url(../../../../images/retailer/filterlogo.png);
            background-repeat: no-repeat;
            background-size: 20px;
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }

        .filter-text {
            display: inline-block;

            > p {
                font-weight: bold;
                font-size: 18px;
                margin: 0;
            }
        }


        .btn-reset-filter {
            display: inline-block;
            border: 1px solid map-get($colors, primary-btns);
            background-color: white;
            width: 90px;
            height: 30px;
            text-align: center;
            border-radius: 5px;
            margin-bottom: 10px;
            cursor: pointer;

            > p {
                color: map-get($colors, primary-btns);
                margin: 0;
                font-size: 15px;
                margin-top: 3px;
                text-transform: uppercase;
            }
        }
    }

    .section-box {
        height: auto;

        .header-section-box {
            border-bottom: 1px solid #B5B5B5;
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .header-collapse {
                display: inline-flex;
                width: 100%;
                justify-content: space-between;
                cursor: pointer;
            }

            .name-section {
                font: Bold 16px/18px Roboto;
                letter-spacing: 0;
                color: #333333;
            }
            /*.fas-restyle {
                font-size: 18px !important;
            }*/
            .fas-position.fa-angle-up {
                position: relative !important;
                bottom: 8px;
                left: 4px;
            }

            .w-auto {
                width: auto;
            }

            .link-select-all {
                text-decoration: underline;
                font: Regular 14px/18px Roboto;
                display: inline-block;
                color: map-get($colors, primary-btns);
                cursor: pointer;
            }
        }

        .header-collapse {
            cursor: pointer;
        }

        .option-container {
            border-bottom: 1px solid #B5B5B5;
            padding: 15px 0 5px 0;

            .more-filters, .more-filters-categories {
                color: map-get($colors, primary-btns);
                text-decoration: underline;
                cursor: pointer;
                display: inline-block;
                margin-top: 5px;
            }

            .filter-arrow, .filter-arrow-categories {
                display: inline-block;
                width: 8px;
                height: 5px;
                background: transparent url('../../../../images/retailer/filter-arrow.png') 0% 0% no-repeat padding-box;
            }

            .trans-arrow, .trans-arrow-categories {
                transform: matrix(0, -1, 1, 0, 0, 0);
            }



            .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
            .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
                background-color: map-get($colors, primary-btns);
            }

            .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
            .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
                background-image: none;
            }
        }
    }
}

@media screen and (max-width: 767px) {

    .filter_left {
        display: none;
    }
}
