@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .search-box-write {
    line-height: 15px !important; }
  /**::-ms-backdrop, .btn-search {
        background-size: 30px !important;
        background-position: 9px center !important;
    }*/
  /**::-ms-backdrop, .contact-logo-text {
        background-position: -30px center !important;
        background-size: 105px !important;
    }*/
  /* IE11 */ }

.alert {
  display: flex;
  justify-content: space-between; }

.container-alert-width-d {
  position: absolute;
  width: 70%;
  top: 45px;
  left: 40px; }

@media screen and (max-width: 767px) {
  .container-alert-width-m {
    position: relative;
    width: 70%;
    top: 15px;
    left: 0px; } }

@media screen and (max-width: 767px) {
  .alert-d,
  .container-alert-width-d {
    display: none; } }

.container-all {
  height: 100%;
  display: flex;
  width: 100%;
  /*padding: 0px 15px;*/ }

.navbar-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  color: #ebedec;
  /*background-color: white;*/
  padding: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px 2px #DCDCDC; }

.logo-content-home {
  padding: 0px; }
  .logo-content-home .menu-white {
    display: none; }
    .logo-content-home .menu-white .menu-line-white {
      display: none; }

.search-box-container {
  padding: 0px;
  margin-top: 33px;
  text-align: right; }

.navbar-content .container-logos {
  max-width: 1024px;
  background: white;
  background-repeat: no-repeat;
  background-position: top center;
  height: 90px;
  padding: 0px;
  margin: 0 auto;
  margin-bottom: 1px solid #04A4EB; }

.search-box {
  display: inline; }

.flag-drop {
  width: 27px;
  cursor: pointer; }

.search-box-write {
  width: calc(100% - 300px);
  height: 30px;
  padding: 10px;
  font-size: 14px;
  line-height: 30px;
  background-color: #d7d7d7;
  border-bottom-left-radius: 33px;
  border-top-left-radius: 33px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 0.63; }

.container-search-btn {
  border-radius: 15px;
  margin-left: -4px;
  background-color: #ffffff;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  min-width: 0px;
  width: 50px !important; }

#selectLanguage {
  position: absolute;
  top: 45px;
  right: 0;
  width: 360px; }

.btn-search {
  width: 100%;
  height: 30px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #04A4EB;
  color: white;
  font-family: OpenSans-SemiBold;
  font-size: 11px;
  border: none;
  outline: none;
  background-image: url(../../../../images/navbar/icon_search.png);
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: 17px center; }

.container-contacts,
.container-dwn-pdf,
.container-dwn-exc {
  min-width: 0px;
  vertical-align: middle;
  width: auto;
  height: 30px;
  display: inline-block; }

.contact-logo-text {
  font-family: OpenSans-Semibold;
  text-align: left;
  line-height: 30px;
  width: 30px;
  height: 30px;
  color: #4a4a4a;
  background-color: #ffffff;
  background-image: url(../../../../images/navbar/email.png);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 5px;
  margin-right: 0;
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
  /*background: transparent url('img/email.png') 0% 0% no-repeat padding-box;*/
  border: 1px solid #04A4EB;
  border-radius: 18px;
  opacity: 1; }

.contact-pdf-download,
.contact-exc-download {
  font-family: OpenSans-Semibold;
  text-align: left;
  line-height: 30px;
  width: 30px;
  height: 30px;
  color: #4a4a4a;
  background-color: #ffffff;
  background-image: url(../../../../images/navbar/download.png);
  background-repeat: no-repeat;
  background-position: 6px;
  margin-right: 0;
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
  border: 1px solid #04A4EB;
  border-radius: 18px;
  opacity: 1; }

.contact-decoration:hover,
.contact-pdf-download,
.contact-exc-download {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  background-color: transparent; }

.container-lang {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px; }
  .container-lang .lang-en {
    margin: 0px auto;
    display: inline;
    bottom: 5px;
    right: 5px;
    width: 25px; }
  .container-lang .lang-it {
    margin: 0px auto;
    display: inline;
    bottom: 5px;
    right: 5px;
    width: 25px; }

.logo-container {
  height: 100%; }

.position-slider {
  display: inline-block;
  position: relative;
  top: 30%;
  float: right; }
  .position-slider .slider-change-page {
    width: 120px;
    display: inline-block;
    margin-left: 15px;
    border: 1px solid transparent; }
    .position-slider .slider-change-page p {
      font-size: 15px;
      text-transform: uppercase;
      color: black;
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px; }

.slider-change-page:hover,
.IsActive {
  border-radius: 33px;
  border: 1px solid black !important;
  font-weight: bold;
  color: black;
  text-align: center;
  text-decoration: none; }

.img-logo-amilon {
  max-width: 90px;
  margin: 20px 0px 15px 0; }

.logo-amilon {
  padding: 0px;
  height: 100%;
  margin-right: 0; }

.under-navbar {
  height: 45px;
  background-color: white;
  position: fixed;
  z-index: 10;
  top: 90px;
  left: 0;
  width: 100%;
  background: #333333 0% 0% no-repeat padding-box !important;
  opacity: 1; }

.under-navbar-content {
  position: relative;
  font-size: 0;
  line-height: 55px;
  height: 55px;
  max-width: 1024px;
  height: 100%;
  /*padding: 0 15px;*/
  margin: 0 auto; }

.all-categories-title {
  height: 45px;
  display: inline-block; }
  .all-categories-title .title {
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1; }

.container-btn-filter {
  margin-top: 20px;
  text-align: center; }
  .container-btn-filter .container-filter-mobile {
    /*margin-right: 30px;*/
    display: inline-block; }
    @media screen and (max-width: 337px) {
      .container-btn-filter .container-filter-mobile {
        /*margin-right: 20px*/ } }
    .container-btn-filter .container-filter-mobile .btn-filter {
      cursor: pointer;
      font-family: OpenSans-Semibold;
      text-align: left;
      line-height: 30px;
      width: 40px;
      height: 40px;
      color: #4a4a4a;
      background-color: #ffffff;
      background-image: url(../../../../images/retailer/filterlogo-blue.png);
      background-size: 21px;
      background-repeat: no-repeat;
      background-position: 9px center;
      margin-right: 0;
      vertical-align: middle;
      display: inline-block;
      border: 1px solid #04A4EB;
      border-radius: 28px;
      opacity: 1; }
      .container-btn-filter .container-filter-mobile .btn-filter p {
        display: inline-block;
        margin: 0 0 10px; }
      .container-btn-filter .container-filter-mobile .btn-filter .style-count {
        border: 1px solid #04A4EB;
        display: inline-block;
        position: relative;
        position: relative;
        bottom: 1px;
        right: 36px;
        width: 30px;
        height: 30px;
        color: white;
        border-left: 0;
        /*background: url(../../../../images/retailer/filterlogo-white.png) no-repeat #04A4EB;*/
        background-position: center;
        background-size: 20px;
        font-family: OpenSans-Extrabold;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px; }
        @media screen and (max-width: 321px) {
          .container-btn-filter .container-filter-mobile .btn-filter .style-count {
            left: initial;
            right: -11px; } }
        @media screen and (max-width: 541px) {
          .container-btn-filter .container-filter-mobile .btn-filter .style-count {
            left: initial;
            right: 32px; } }

.loc-filter-btn {
  vertical-align: top; }

.menu {
  min-width: 0px;
  height: 45px;
  width: 85px;
  display: inline-block; }
  @media screen and (max-width: 1070px) {
    .menu {
      margin-left: 15px; } }

.menu-line {
  max-width: 35px;
  margin: 0 !important;
  cursor: pointer; }

.title {
  display: inline-block;
  vertical-align: middle;
  margin: 0; }

.digital-catalogue {
  display: inline-block;
  width: auto;
  min-width: 0px;
  font-size: 100em;
  position: relative;
  top: 20px; }

.digital-catalogue > h2 > b {
  font-size: 22px;
  margin-bottom: 12px;
  font-family: Roboto;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0;
  color: #333333;
  opacity: 1; }

.img-close-btn-category-p {
  margin-left: 10px;
  cursor: pointer; }

.share-btn-nav {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle; }
  .share-btn-nav .share-btn {
    cursor: pointer;
    font-family: OpenSans-Semibold;
    text-align: left;
    line-height: 30px;
    width: 30px;
    height: 30px;
    color: #4a4a4a;
    background-color: #ffffff;
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 6px center;
    margin-right: 0;
    vertical-align: middle;
    display: inline-block;
    /* background: transparent url(img/email.png) 0% 0% no-repeat padding-box; */
    border: 1px solid #04A4EB;
    border-radius: 18px;
    opacity: 1;
    background-image: url(../../../../images/navbar/share-btn.png); }

button:focus {
  outline: 0px; }

@media screen and (min-width: 732px) {
  .btn-navbar-toggle {
    display: none; } }

@media screen and (max-width: 900px) {
  .position-slider {
    margin: 0; }
    .position-slider .slider-change-page {
      width: 120px;
      margin-left: 0; }
      .position-slider .slider-change-page > p {
        font-size: 15px; }
  .search-box-write {
    width: calc(100% - 290px); } }

@media screen and (max-width: 1066px) {
  .container-all {
    padding: 0px 15px; } }

@media screen and (max-width: 854px) {
  .digital-catalogue > h2 > b {
    font-size: 18px; }
  .container-all {
    padding: 0px 15px; } }

@media screen and (max-width: 576px) {
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; } }

@media screen and (max-width: 767px) {
  .position-slider {
    display: none; }
  .logo-container > .digital-catalogue {
    display: inline-block;
    width: auto;
    min-width: 0px; }
  .digital-catalogue > h2 > b {
    font-size: 1.0rem; }
  .container-logos .logo-content-home .menu-white {
    display: none; }
    .container-logos .logo-content-home .menu-white .menu-line-white {
      display: block;
      margin: 34px 0px 15px 0px !important;
      width: 25px;
      cursor: pointer; }
  .search-box-container {
    position: absolute;
    right: 15px;
    display: block; }
    .search-box-container .flag-drop {
      width: 41px;
      height: 41px;
      cursor: pointer; }
    .search-box-container .share-btn-nav {
      display: none; }
  .btn-navbar-toggle {
    display: none; }
  .container-contacts {
    display: none !important; }
  .menu-mobile {
    min-width: 0px;
    height: 45px;
    margin-left: 10px;
    width: 55px;
    display: inline-block; }
  .search-box {
    display: none !important; }
  .img-logo-amilon {
    max-width: 100px;
    margin: 15px 10px 15px 0; }
  .under-navbar {
    display: block;
    height: 56.2px !important;
    background-color: white;
    background: #333333 0% 0% no-repeat padding-box;
    opacity: 1; }
    .under-navbar .menu {
      display: none; }
  .title {
    color: #4a4a4a;
    display: inline-block !important;
    vertical-align: middle;
    font-family: OpenSans-Semibold;
    font-size: 1.357rem;
    margin-left: 15px; }
  .navbar-content {
    background-image: none; }
  .padding-all-giftcards-categories,
  .padding-catalogue-download,
  .all-giftcards-categories {
    border-radius: 15px; }
  .container-btn-filter .container-filter-mobile .btn-filter {
    color: #04A4EB;
    background-color: white; } }

@media screen and (max-width: 540px) {
  .menu {
    width: 50px; }
  .all-categories-title .title {
    font-size: 14px; } }

@media screen and (max-width: 437px) {
  .digital-catalogue > h2 > b {
    font-size: 15px; }
  .img-logo-amilon {
    margin: 25px 10px 15px 0; }
  .digital-catalogue {
    display: none !important; } }

@media screen and (max-width: 380px) {
  .title {
    font-size: 16px; }
  .digital-catalogue {
    display: none !important; } }

@media screen and (max-width: 370px) {
  .all-categories-title .title {
    font-size: 12px; }
  .menu {
    width: 40px; }
    .menu .menu-line {
      max-width: 25px; }
  .img-logo-amilon {
    max-width: 70px;
    margin: 30px 0px 15px 0; }
  .share-btn-nav .share-btn {
    font-size: 10px; }
  .flag-drop {
    width: 20px; }
  .digital-catalogue {
    display: none !important; } }

.body-single-category {
  font-size: 14px; }
  .body-single-category .sp-img-proprieties-container {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 40px;
    background-color: white; }
    .body-single-category .sp-img-proprieties-container .sp-container-img {
      padding-left: 0px;
      padding-right: 50px; }
      .body-single-category .sp-img-proprieties-container .sp-container-img .sp-card {
        padding-right: 60px;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: transparent;
        background-clip: border-box;
        border: 0px;
        border-radius: .25rem; }
        .body-single-category .sp-img-proprieties-container .sp-container-img .sp-card .sp-container-img-shadow {
          box-shadow: rgba(74, 74, 74, 0.6) 0px 8px 50px -5px; }
        .body-single-category .sp-img-proprieties-container .sp-container-img .sp-card .card-img-top {
          width: 97%; }
  .body-single-category .sp-container-info {
    padding: 0px; }
    .body-single-category .sp-container-info .sp-container-text > h3 {
      font-family: OpenSans-Light;
      font-size: 1.071rem;
      line-height: 1.1;
      margin-bottom: 10px; }
    .body-single-category .sp-container-info .sp-container-text > h3 > b {
      font-family: Roboto;
      font-size: 16px;
      line-height: 1.1;
      text-align: left;
      letter-spacing: 0;
      color: #333333;
      opacity: 1; }
    .body-single-category .sp-container-info .sp-title {
      font-family: Roboto;
      font-size: 25px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.1;
      text-align: left;
      letter-spacing: 0;
      color: #04A4EB;
      opacity: 1; }
    .body-single-category .sp-container-info .sp-hr {
      width: 100px;
      border-top-width: 4.5px;
      border-top-style: solid;
      margin: 0 auto 40px 0;
      border-top-color: #e6dee9; }
    .body-single-category .sp-container-info .sp-description {
      font-size: 1.071rem; }

.filter_left {
  width: 220px; }

.flex_flow {
  margin-left: auto; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .flex_flow {
      display: block; } }

.placeholder-name-filter {
  width: 100%; }
  .placeholder-name-filter .container-filter-selected {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    margin-bottom: 10px;
    margin-right: 10px;
    text-align: center; }
    .placeholder-name-filter .container-filter-selected .filter-box-selected {
      background-color: #04A4EB;
      border-radius: 5px;
      padding: 5px;
      margin-right: 10px;
      margin-bottom: 10px; }
      .placeholder-name-filter .container-filter-selected .filter-box-selected .filter-type,
      .placeholder-name-filter .container-filter-selected .filter-box-selected .filter-selected {
        display: inline-block;
        color: white;
        margin: 0; }
      .placeholder-name-filter .container-filter-selected .filter-box-selected .filter-type {
        text-transform: capitalize; }
      .placeholder-name-filter .container-filter-selected .filter-box-selected .remove-filter {
        display: inline-block;
        background: transparent url("../../../../images/retailer/delete-filter.png") 0% 0% no-repeat padding-box;
        width: 12px;
        height: 9px;
        transform: matrix(-1, 0, 0, -1, 0, 0);
        margin: 0;
        margin-left: 10px;
        cursor: pointer; }

.sp-retailer-container-img {
  height: 192px;
  /*width: 220px;*/
  background-color: #ffffff;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 25px;
  cursor: pointer;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  text-align: center;
  position: relative; }
  .sp-retailer-container-img .select-lenguage {
    margin: 0px auto;
    display: inline;
    bottom: 5px;
    position: absolute;
    right: 5px;
    width: 25px; }

.retailer-img {
  max-width: 100%;
  border-radius: 14px; }

.dwnl-pdf {
  position: unset;
  right: 0;
  top: 0; }
  .dwnl-pdf .dwnl-text-align {
    text-align: center; }
    .dwnl-pdf .dwnl-text-align .dwnl-center {
      display: inline-block;
      color: #ffffff;
      background-color: #4a4a4a;
      margin-left: 10px;
      width: 130px;
      height: 30px;
      font-size: 0.786rem;
      line-height: 29px;
      border: solid 1.5px #4a4a4a;
      border-radius: 15px;
      cursor: pointer;
      max-width: none;
      padding-right: 0;
      box-shadow: 0px 8px 20px -5px rgba(74, 74, 74, 0.5);
      user-select: none;
      text-align: center; }

.sp-single-container-retailer {
  width: 20%;
  vertical-align: top;
  padding: 0; }
  .sp-single-container-retailer a {
    text-decoration: none; }
    .sp-single-container-retailer a:hover {
      text-decoration: none; }
  .sp-single-container-retailer .inline-flag-name {
    position: absolute;
    width: 100%;
    left: 0;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font: 14px/15px Roboto;
    letter-spacing: 0;
    color: #333333;
    opacity: 1;
    text-transform: capitalize;
    margin-top: 20px;
    text-align: initial; }
  .sp-single-container-retailer .country-flag-icon {
    display: block;
    margin-top: 53px;
    margin-left: 0;
    height: 20px; }

.sp-retailer-container-img:hover {
  border-width: 3px;
  transform: scale(1.05);
  text-decoration: none; }
  .sp-retailer-container-img:hover .select-lenguage {
    margin: 0px auto;
    display: inline;
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 25px; }

.retailer-name > p {
  color: #4a4a4a;
  text-decoration: none;
  font-family: OpenSans-Semibold;
  font-size: 9.8px;
  text-align: center;
  text-transform: uppercase; }

.retailer-height {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  border-radius: 14px;
  overflow: hidden; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .retailer-height {
      display: block; } }

.back-to-catalogue,
.back-to-catalogue:hover {
  display: inline-block;
  color: #04A4EB;
  text-decoration: none; }

@media screen and (max-width: 1023px) {
  .sp-single-container-retailer {
    width: 25%; }
    .sp-single-container-retailer .inline-flag-name {
      white-space: nowrap;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis; } }

@media screen and (max-width: 880px) {
  .sp-single-container-retailer {
    width: 25%; }
    .sp-single-container-retailer .inline-flag-name-single-category {
      white-space: nowrap;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 28%; } }

@media screen and (max-width: 767px) {
  .sp-single-container-retailer {
    width: 33%; }
    .sp-single-container-retailer .inline-flag-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .filter_left {
    display: none; }
  .retailer-img {
    /*margin: 0 auto;*/ }
  .body-single-category .sp-img-proprieties-container .sp-container-img {
    padding: 0;
    margin-bottom: 20px; }
  .placeholder-name-filter {
    display: none; } }

@media screen and (max-width: 500px) {
  .sp-single-container-retailer {
    width: 50%; }
    .sp-single-container-retailer .inline-flag-name {
      white-space: nowrap;
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis; }
  .filter_left {
    display: none; }
  .retailer-img {
    /*margin: 0 auto;*/ }
  .body-single-category .sp-img-proprieties-container .sp-container-img {
    padding: 0;
    margin-bottom: 20px; }
  .placeholder-name-filter {
    display: none; } }

.single-retailer-body {
  background: white;
  opacity: 1; }
  .single-retailer-body .body-content-retailer {
    background: white;
    max-width: 1024px;
    margin: 165.4px auto 0; }
    .single-retailer-body .body-content-retailer .container-left-retailer {
      height: 100%;
      padding: 0 25px 0 30px;
      margin-top: 20px; }
      .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer {
        height: 100%;
        margin-left: 7px; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .container-retailer-img {
          width: 145px;
          height: 85px;
          background-color: #ffffff;
          border-radius: 15px; }
          .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .container-retailer-img .under-container-retailer-img {
            height: 100%; }
            .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .container-retailer-img .under-container-retailer-img .single-retailer-img {
              display: block;
              max-width: 100%;
              max-height: 100%;
              border-radius: 15px; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .gift-card-title {
          font-family: OpenSans-Semibold;
          font-size: 1rem; }
          .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .gift-card-title .gift-card {
            text-align: left;
            font-family: Roboto;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0;
            color: #333333; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .title-retailer-name {
          text-transform: uppercase;
          margin-top: 0;
          margin-bottom: 20px;
          text-align: left;
          font: Bold 25px/30px Roboto;
          letter-spacing: 0;
          color: #04A4EB;
          opacity: 1; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .country-flag-icon {
          height: 20px; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .btn-category-name .title-category-name {
          color: white;
          display: inline-block;
          font-size: 16px;
          line-height: 19px;
          padding: 7px 15px;
          border-radius: 10px;
          margin: 0;
          background-color: #04A4EB;
          background: #04A4EB 0% 0% no-repeat padding-box;
          border-radius: 22px;
          opacity: 1; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .share-link-page {
          display: inline-block; }
          .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .share-link-page .share-link-page-title {
            margin: 30px 0 0px 0;
            font-family: Roboto;
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
            text-align: left;
            letter-spacing: 0;
            color: #333333;
            opacity: 1;
            text-transform: uppercase; }
          .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .share-link-page .share-btn-nav-single-retailer {
            display: inline-block;
            vertical-align: middle;
            margin-top: 5px; }
            .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .share-link-page .share-btn-nav-single-retailer .share-btn-single-retailer {
              cursor: pointer;
              font-family: OpenSans-Semibold;
              text-align: left;
              line-height: 30px;
              width: 30px;
              height: 30px;
              color: #4a4a4a;
              background-color: #ffffff;
              background-size: 15px;
              background-repeat: no-repeat;
              background-position: 6px center;
              margin-right: 0;
              vertical-align: middle;
              display: inline-block;
              /* background: transparent url(img/email.png) 0% 0% no-repeat padding-box; */
              border: 1px solid #04A4EB;
              border-radius: 18px;
              opacity: 1;
              background-image: url(../../../../images/navbar/share-btn.png); }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .short-description {
          margin-top: 30px;
          text-align: left;
          font-family: Roboto;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0;
          color: #333333;
          opacity: 1; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .change-lang {
          margin-top: 10px;
          cursor: pointer;
          display: inline-block; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .amounts-title {
          margin: 30px 0 20px 0;
          font-family: Roboto;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          text-align: left;
          letter-spacing: 0;
          color: #333333;
          opacity: 1; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .amount-currency {
          max-height: 200px;
          overflow: hidden;
          margin: 0 -5px;
          transition: max-height .5s; }
          .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .amount-currency .single-amount-currency {
            height: auto;
            width: auto;
            display: inline-block; }
          .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .amount-currency .box-currency {
            font-family: OpenSans-Semibold;
            font-size: 0.8rem;
            text-align: center;
            padding: 7px 0;
            margin: 5px;
            font-weight: bold;
            background: #333333 0% 0% no-repeat padding-box;
            border-radius: 22px;
            opacity: 1;
            color: white;
            width: 98px;
            height: 36px; }
        .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .show-countries {
          margin-top: 20px; }
          .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .show-countries .container-link-change-lang {
            display: inline-block; }
            .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer .show-countries .container-link-change-lang .change-country {
              color: black;
              text-decoration: underline;
              background-color: transparent; }
    .single-retailer-body .body-content-retailer .delete-margin {
      padding: 0px; }
      .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer {
        padding: 0px 50px 50px 25px;
        border-left: 1px solid #04A4EB;
        margin-top: 20px;
        margin-bottom: 20px; }
        .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .wrap-content-right-retailer {
          height: auto; }
          .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .wrap-content-right-retailer .container-img-retailer-bottom {
            padding: 0 0 30px; }
            .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .wrap-content-right-retailer .container-img-retailer-bottom .container-img-retailer-size .img-retailer-size {
              display: block;
              margin: 0 auto;
              max-width: 80%;
              border: 1px solid #707070;
              border-radius: 20px;
              opacity: 1; }
        .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .gift-card-details-title > h6 {
          margin-top: 30px;
          margin-bottom: 20px;
          font-weight: 600;
          font-size: 14px;
          text-align: left;
          font: Bold 16px/20px Roboto;
          letter-spacing: 0;
          color: #333333;
          opacity: 1; }
        .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box {
          margin: 10px 0; }
          .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box .under-margin-box {
            height: auto; }
            .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box .under-margin-box .icon {
              display: inline-block; }
              .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box .under-margin-box .icon .img-icon-validity {
                background-image: url(../../../../images/pages/icone_validity.svg); }
              .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box .under-margin-box .icon .img-icon {
                background-image: url(../../../../images/pages/icon_check.png);
                background-repeat: no-repeat;
                background-position: center;
                display: inline-block;
                vertical-align: middle;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                margin: 0 10px 0 20px; }
              .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box .under-margin-box .icon .img-icon-else {
                background-image: url(../../../../images/pages/icon_close_white.png);
                border: 1px solid #333333;
                opacity: 1; }
              .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box .under-margin-box .icon .img-icon-online {
                background-image: url(../../../../images/pages/icon_check.png);
                background-repeat: no-repeat;
                background-position: center;
                display: inline-block;
                vertical-align: middle;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                margin: 0 10px 0 20px; }
              .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box .under-margin-box .icon .img-icon-offline {
                background-image: url(../../../../images/pages/icone_store.svg);
                background-repeat: no-repeat;
                background-position: center;
                display: inline-block;
                vertical-align: middle;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                margin: 0 10px 0 20px; }
            .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box .under-margin-box .details-title {
              display: inline-block; }
            .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .gift-card-details .margin-box .under-margin-box .details-title > h6 {
              margin-top: 2px;
              margin-bottom: 0px;
              font-size: 14px;
              margin-bottom: 0px;
              font-weight: 500;
              line-height: 1.1;
              color: inherit;
              text-align: left;
              font: 16px/20px Roboto;
              letter-spacing: 0;
              color: #333333;
              opacity: 1; }
        .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .where-to-reedem .where-to-reedem-title > h6 {
          margin-top: 30px;
          margin-bottom: 20px;
          font-weight: 600;
          font-size: 14px;
          text-align: left;
          font: Bold 16px/20px Roboto;
          letter-spacing: 0;
          color: #333333;
          opacity: 1; }
        .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer .notes .notes-title > h6 {
          margin-top: 30px;
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 14px; }

@media screen and (max-width: 767px) {
  .single-retailer-body .body-content-retailer {
    margin: 145px auto 0 !important;
    padding-bottom: 100px; }
    .single-retailer-body .body-content-retailer .container-left-retailer {
      padding: 0; }
      .single-retailer-body .body-content-retailer .container-left-retailer .padd-container-left-retailer {
        padding: 20px 15px 10px; }
    .single-retailer-body .body-content-retailer .delete-margin .container-right-retailer {
      padding: 15px;
      padding-top: 50px;
      border: 0; }
    .single-retailer-body .body-content-retailer .delete-margin-sr .container-right-retailer .wrap-content-right-retailer .container-img-retailer-bottom .container-img-retailer-size .img-retailer-size {
      display: block;
      max-width: 80%;
      border: 1px solid #707070;
      border-radius: 30px;
      opacity: 1;
      margin: 0; }
  .delete-margin-sr {
    margin: 0; } }

.language-container {
  position: fixed;
  top: 25%;
  left: 30%;
  width: 40%;
  height: auto;
  background: #4a4a4a;
  background-color: rgba(74, 74, 74, 0.9);
  align-items: center;
  z-index: 112; }
  .language-container .language-title .language-retailer-name {
    text-align: center;
    font-size: 22px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: white;
    margin-left: 20%;
    margin-right: 20%;
    word-break: break-word; }
  .language-container .language-title .language-select-country {
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: white; }
  .language-container .close-btn {
    position: absolute;
    right: 20px;
    top: 20px; }
    .language-container .close-btn .img-close-btn {
      cursor: pointer;
      max-width: 110px;
      margin: 0px 0px 0px auto; }
  .language-container .country-container {
    display: flex !important;
    width: 100%;
    height: 90%;
    margin-top: 0%;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px; }
    .language-container .country-container .margin-country-container {
      font-size: 0;
      max-width: 80%;
      margin: 0 auto;
      text-align: center; }
      .language-container .country-container .margin-country-container .container-single-country {
        display: inline-block;
        margin: 20px; }
        .language-container .country-container .margin-country-container .container-single-country .single-country .img-country {
          margin: 0px auto;
          max-width: 110px; }
        .language-container .country-container .margin-country-container .container-single-country .single-country .name-country {
          font-size: 14px;
          text-align: center;
          margin-top: 10px;
          margin-bottom: 10px;
          color: white; }

@media screen and (max-width: 1023px) {
  .language-container {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: #4a4a4a;
    background-color: rgba(74, 74, 74, 0.9);
    align-items: center; } }

@media screen and (max-width: 767px) {
  .language-container .country-container {
    height: auto; } }

.wrap-filter {
  max-height: 1200px;
  overflow-y: hidden;
  -webkit-transition: max-height 0.3s ease-in-out;
  -moz-transition: max-height 0.3s ease-in-out;
  -o-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  position: fixed;
  z-index: 10;
  left: 0;
  width: 100%;
  background-color: #ffffff; }
  @media screen and (max-width: 767px) {
    .wrap-filter {
      overflow-y: scroll;
      height: calc(100vh - 90px); } }
  .wrap-filter.closed {
    max-height: 0px;
    -webkit-transition: max-height 0.3s ease-in-out;
    -moz-transition: max-height 0.3s ease-in-out;
    -o-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
    position: fixed;
    z-index: 10;
    left: 0;
    width: 100%; }
  .wrap-filter .under-wrap-filter {
    height: auto; }
    .wrap-filter .under-wrap-filter .margin-filter {
      max-width: 1024px;
      padding: 20px 30px 60px;
      margin: 0 auto;
      margin-bottom: 100px; }
      .wrap-filter .under-wrap-filter .margin-filter .container-body-filter {
        height: auto; }
        .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin {
          font-family: OpenSans-Light;
          font-size: 1.357rem;
          margin-top: 30px;
          padding-bottom: 30px; }
          @media screen and (max-height: 900px) {
            .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin {
              margin-top: 0px;
              margin-bottom: 30px; } }
          .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin span {
            text-transform: uppercase;
            font-weight: bold; }
          .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin .search-box-filter-margin {
            display: inline;
            margin-left: 160px; }
            @media screen and (max-height: 900px) {
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin .search-box-filter-margin {
                margin-left: 70px; } }
            .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin .search-box-filter-margin .search-box-filter {
              width: calc(100% - 580px);
              height: 30px;
              padding: 10px;
              font-size: 14px;
              line-height: 28px;
              background-color: #d7d7d7;
              border-radius: 15px;
              border: none;
              outline: none;
              display: inline-block;
              vertical-align: middle;
              background: #FFFFFF 0% 0% no-repeat padding-box;
              border: 1px solid #707070;
              opacity: 0.63; }
              @media screen and (max-height: 900px) {
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin .search-box-filter-margin .search-box-filter {
                  width: calc(100% - 480px); } }
        .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter {
          height: 200px;
          margin: 0; }
          @media screen and (max-width: 767px) {
            .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter {
              height: auto; } }
          .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter {
            height: 100%;
            margin: 0;
            display: flex; }
            @media screen and (max-width: 767px) {
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter {
                display: block;
                height: auto; } }
            .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .left-column {
              padding: 0 30px;
              border-left: solid 1px #04A4EB;
              height: 100%; }
              @media screen and (max-width: 767px) {
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .left-column {
                  margin-bottom: 20px;
                  border-bottom: solid 1px #e0e0e0;
                  border-left: 0;
                  padding: 0;
                  margin-top: 20px; } }
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .left-column .container-option-margin {
                margin-bottom: 20px;
                font-size: 0; }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .left-column .container-option-margin .container-btn-text .under-container-btn-text {
                  display: inline-block;
                  position: relative;
                  width: auto;
                  font-weight: normal;
                  font-size: 14px;
                  padding-left: 35px;
                  padding-right: 0;
                  cursor: pointer;
                  user-select: none;
                  margin: 0px; }
                  .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .left-column .container-option-margin .container-btn-text .under-container-btn-text .container-text {
                    font-weight: normal;
                    font-size: 14px;
                    user-select: none;
                    cursor: pointer; }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .left-column .container-option-margin .container-btn-text .input-btn {
                  position: absolute;
                  opacity: 0;
                  margin: 4px 0 0;
                  line-height: normal; }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .left-column .container-option-margin .container-btn-text .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 23px;
                  width: 23px;
                  border: solid 1px #e7e7e7;
                  background-color: #ffffff; }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .left-column .container-option-margin .container-btn-text input:checked ~ .checkmark {
                  background-color: #04A4EB;
                  background-image: url(../../../../images/components/icon_check_white.svg);
                  color: #ffffff;
                  background-size: 70%;
                  background-position: center;
                  background-repeat: no-repeat; }
            .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .center-column {
              padding: 0 30px;
              border-left: solid 1px #04A4EB;
              height: 100%; }
              @media screen and (max-width: 767px) {
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .center-column {
                  margin-bottom: 20px;
                  border-bottom: solid 1.5px #e0e0e0;
                  border-left: 0;
                  padding: 0;
                  max-width: 100% !important; } }
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .center-column .title-margin {
                margin-bottom: 20px;
                position: relative; }
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .center-column .container-option-margin {
                margin-bottom: 20px;
                font-size: 0; }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .center-column .container-option-margin .container-btn-text .under-container-btn-text {
                  display: inline-block;
                  position: relative;
                  width: auto;
                  font-weight: normal;
                  font-size: 14px;
                  padding-left: 35px;
                  padding-right: 0;
                  cursor: pointer;
                  user-select: none;
                  margin: 0px; }
                  .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .center-column .container-option-margin .container-btn-text .under-container-btn-text .container-text {
                    font-weight: normal;
                    font-size: 14px;
                    user-select: none;
                    cursor: pointer; }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .center-column .container-option-margin .container-btn-text .input-btn {
                  position: absolute;
                  opacity: 0;
                  margin: 4px 0 0;
                  line-height: normal; }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .center-column .container-option-margin .container-btn-text .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 23px;
                  width: 23px;
                  border: solid 1px #e7e7e7;
                  background-color: #ffffff; }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .center-column .container-option-margin .container-btn-text input:checked ~ .checkmark {
                  background-color: #04A4EB;
                  background-image: url(../../../../images/components/icon_check_white.svg);
                  color: #ffffff;
                  background-size: 70%;
                  background-position: center;
                  background-repeat: no-repeat; }
            .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column {
              padding: 0 30px;
              height: 100%; }
              @media screen and (max-width: 767px) {
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column {
                  margin-bottom: 20px;
                  border-left: 0;
                  padding: 0;
                  max-width: 100%; } }
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column .title-margin {
                margin-bottom: 20px;
                position: relative; }
                @media screen and (max-height: 900px) {
                  .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column .title-margin {
                    margin-bottom: -15px; } }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column .title-margin h5 {
                  font-family: OpenSans-Semibold;
                  font-size: 14px;
                  margin-top: 0;
                  text-transform: uppercase;
                  margin-bottom: 20px; }
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column .container-option-dropdown {
                margin-bottom: 30px; }
                .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column .container-option-dropdown .under-container-option-dropdown {
                  font-size: 0; }
                  .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column .container-option-dropdown .under-container-option-dropdown .input-dropbox {
                    display: none;
                    font-family: inherit;
                    font-size: inherit;
                    line-height: inherit; }
                  .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column .container-option-dropdown .under-container-option-dropdown .dropbox-select {
                    width: 100%;
                    font-family: OpenSans-Semibold;
                    color: #ffffff;
                    padding-top: 0;
                    border: solid 2.5px #e7e7e7;
                    outline: none;
                    font-size: 14px; }
                    .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-option-filter .under-container-option-filter .right-column .container-option-dropdown .under-container-option-dropdown .dropbox-select option {
                      background: #ffffff;
                      color: #4a4a4a; }
        .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .margin-top {
          margin-top: 30px; }
        .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .country-title-margin {
          margin-top: 15px;
          position: relative; }
          .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .country-title-margin h5 {
            text-transform: capitalize; }
        .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-countries {
          height: auto; }
          .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-countries .country-select {
            border: solid 1px black;
            width: 100%;
            border-radius: 50px; }
          .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-countries .container-check-text {
            display: inline-block;
            width: 180px;
            height: 40px; }
            .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-countries .container-check-text .under-container-check-text {
              display: inline-block;
              position: relative;
              width: auto;
              font-weight: normal;
              font-size: 14px;
              padding-top: 2px;
              padding-left: 35px;
              padding-right: 0;
              cursor: pointer;
              user-select: none; }
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-countries .container-check-text .under-container-check-text .input-btn {
                position: absolute;
                opacity: 0;
                margin: 4px 0 0;
                line-height: normal; }
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-countries .container-check-text .under-container-check-text .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 23px;
                width: 23px;
                border: solid 1.5px #e7e7e7;
                background-color: #ffffff; }
              .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-countries .container-check-text .under-container-check-text input:checked ~ .checkmark {
                background-color: #46c37e;
                background-image: url(../../../../images/components/icon_check_white.svg);
                color: #ffffff;
                background-size: 70%;
                background-position: center;
                background-repeat: no-repeat; }
        .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-category .category-select {
          border: solid 1px black;
          border-radius: 50px;
          width: 100%; }
          .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-category .category-select .dropdown-toggle.bs-placeholder {
            color: black;
            background-color: transparent;
            border-radius: 50px; }
        .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-contract .contract-select {
          border: solid 1px black;
          border-radius: 50px;
          width: 100%; }
          .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .container-contract .contract-select .dropdown-toggle.bs-placeholder {
            color: black;
            background-color: transparent;
            border-radius: 50px; }
        .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .reset-apply-btn-filter-container {
          margin-top: 45px;
          height: auto; }
          .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .reset-apply-btn-filter-container .under-reset-apply-btn-filter-container {
            text-align: center;
            direction: inherit; }
            .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .reset-apply-btn-filter-container .under-reset-apply-btn-filter-container .container-reset {
              display: inline-block;
              width: 30%;
              height: 47px;
              font-size: 16px;
              line-height: 29px;
              border-radius: 50px;
              cursor: pointer;
              max-width: none;
              padding-right: 0;
              user-select: none;
              text-align: center;
              background-color: white;
              border: 1px solid #04A4EB;
              color: #04A4EB;
              text-transform: uppercase; }
            .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .reset-apply-btn-filter-container .under-reset-apply-btn-filter-container .container-apply {
              color: #ffffff;
              background-color: #04A4EB;
              margin-left: 10px;
              display: inline-block;
              width: 30%;
              height: 47px;
              font-size: 16px;
              line-height: 29px;
              border-radius: 50px;
              cursor: pointer;
              max-width: none;
              padding-right: 0;
              user-select: none;
              text-align: center;
              border: 0;
              text-transform: uppercase; }

@media screen and (max-width: 807px) {
  .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin {
    display: flex;
    justify-content: space-between; }
    .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin .search-box-filter-margin {
      display: block;
      margin: auto;
      margin-top: 20px; }
      .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin .search-box-filter-margin .search-box-filter {
        width: 100%; }
    .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .select-filter .select-filter-margin .close-btn-filter {
      cursor: pointer;
      display: inline-block;
      background: url(../../../../images/shared/icon_close_black.png);
      width: 20px;
      height: 20px; }
  .wrap-filter .under-wrap-filter .margin-filter .container-body-filter .splitter-border {
    border-bottom: 2px solid lightgray;
    padding-bottom: 20px; } }

@media screen and (max-width: 576px) {
  .col-sm-5 {
    max-width: 100%; } }

.add-overflow {
  /*overflow-y: scroll;*/
  /*height: calc(100vh - 245.4px);*/ }

.custom-radio .custom-control-label::before {
  border-radius: .25rem; }

.custom-control, .custom-checkbox :hover {
  cursor: pointer; }

.container-des-filter {
  height: auto; }
  .container-des-filter .filter-btn-container {
    border-bottom: 1px solid #B5B5B5;
    justify-content: space-between;
    display: flex; }
    .container-des-filter .filter-btn-container .filter-icon {
      background-image: url(../../../../images/retailer/filterlogo.png);
      background-repeat: no-repeat;
      background-size: 20px;
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle; }
    .container-des-filter .filter-btn-container .filter-text {
      display: inline-block; }
      .container-des-filter .filter-btn-container .filter-text > p {
        font-weight: bold;
        font-size: 18px;
        margin: 0; }
    .container-des-filter .filter-btn-container .btn-reset-filter {
      display: inline-block;
      border: 1px solid #04A4EB;
      background-color: white;
      width: 90px;
      height: 30px;
      text-align: center;
      border-radius: 5px;
      margin-bottom: 10px;
      cursor: pointer; }
      .container-des-filter .filter-btn-container .btn-reset-filter > p {
        color: #04A4EB;
        margin: 0;
        font-size: 15px;
        margin-top: 3px;
        text-transform: uppercase; }
  .container-des-filter .section-box {
    height: auto; }
    .container-des-filter .section-box .header-section-box {
      border-bottom: 1px solid #B5B5B5;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      /*.fas-restyle {
                font-size: 18px !important;
            }*/ }
      .container-des-filter .section-box .header-section-box .header-collapse {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        cursor: pointer; }
      .container-des-filter .section-box .header-section-box .name-section {
        font: Bold 16px/18px Roboto;
        letter-spacing: 0;
        color: #333333; }
      .container-des-filter .section-box .header-section-box .fas-position.fa-angle-up {
        position: relative !important;
        bottom: 8px;
        left: 4px; }
      .container-des-filter .section-box .header-section-box .w-auto {
        width: auto; }
      .container-des-filter .section-box .header-section-box .link-select-all {
        text-decoration: underline;
        font: Regular 14px/18px Roboto;
        display: inline-block;
        color: #04A4EB;
        cursor: pointer; }
    .container-des-filter .section-box .header-collapse {
      cursor: pointer; }
    .container-des-filter .section-box .option-container {
      border-bottom: 1px solid #B5B5B5;
      padding: 15px 0 5px 0; }
      .container-des-filter .section-box .option-container .more-filters, .container-des-filter .section-box .option-container .more-filters-categories {
        color: #04A4EB;
        text-decoration: underline;
        cursor: pointer;
        display: inline-block;
        margin-top: 5px; }
      .container-des-filter .section-box .option-container .filter-arrow, .container-des-filter .section-box .option-container .filter-arrow-categories {
        display: inline-block;
        width: 8px;
        height: 5px;
        background: transparent url("../../../../images/retailer/filter-arrow.png") 0% 0% no-repeat padding-box; }
      .container-des-filter .section-box .option-container .trans-arrow, .container-des-filter .section-box .option-container .trans-arrow-categories {
        transform: matrix(0, -1, 1, 0, 0, 0); }
      .container-des-filter .section-box .option-container .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
      .container-des-filter .section-box .option-container .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #04A4EB; }
      .container-des-filter .section-box .option-container .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
      .container-des-filter .section-box .option-container .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        background-image: none; }

@media screen and (max-width: 767px) {
  .filter_left {
    display: none; } }

body {
  background: transparent linear-gradient(177deg, #5BC0CA 0%, #086F92 62%, #D73E6E 100%) 0% 0% no-repeat padding-box; }

.contacts-container {
  position: relative; }
  .contacts-container .under-contacts-container {
    height: auto; }
    .contacts-container .under-contacts-container .contacts-container-margin {
      /*height: calc(100vh - 70.4px);
            padding-top: 40px;
            margin-top: 70.4px;*/
      color: white; }
      .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside {
        max-width: 1024px;
        display: flex;
        /*margin: auto !important;*/
        background-color: white;
        padding: 10px;
        color: black;
        font-family: Roboto !important; }
        .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register {
          margin-top: 10px; }
          .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .title-container {
            font-size: 26px;
            text-transform: uppercase;
            padding-left: 15px; }
          .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .wrap-hr-style {
            padding-left: 15px; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .wrap-hr-style .hr-line-sx {
              width: 100px;
              border-top-width: 4.5px;
              border-top-style: solid;
              border-top-color: #04A4EB;
              margin: 0 auto 40px 0; }
          .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .sx {
            margin-top: 40px; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .sx .column-contact {
              display: flex; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .sx .form-group .text-error {
              color: red; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .sx .form-group .align-check {
              position: relative;
              top: 3px;
              left: 5px;
              width: auto;
              display: inline;
              margin-right: 20px;
              height: 13px; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .sx .form-group .btn-sub {
              display: inline-block;
              font-size: 14px;
              text-align: left;
              line-height: 20px;
              height: 30px;
              color: white;
              background-color: #04A4EB;
              padding: 0 10px;
              border-bottom-left-radius: 15px;
              border-top-left-radius: 15px;
              border-bottom-right-radius: 15px;
              border-top-right-radius: 15px;
              margin: 0 auto 0 0;
              background: #04A4EB;
              border-radius: 18px;
              border: none;
              padding: 5px 40px;
              color: white;
              cursor: pointer; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .sx .padding-text-area {
              padding-left: 15px;
              padding-right: 15px; }
        .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container {
          height: auto;
          max-width: 1024px;
          padding: 0px 30px;
          margin: 0 auto; }
          .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container {
            height: auto;
            margin-top: 10px; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .title-container {
              position: relative;
              margin-top: 20px; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .title-container h3 {
                font-size: 15px;
                margin-top: 20px;
                margin-bottom: 10px;
                font-weight: 500;
                line-height: 1.1;
                color: inherit; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .details {
              position: relative; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .details h1 {
                font-size: 26px;
                text-transform: uppercase;
                margin: 0 0 8px;
                font-weight: 500;
                line-height: 1.1;
                color: inherit; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .hr-line {
              position: relative; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .hr-line hr {
                width: 100px;
                border-top-width: 4.5px;
                border-top-style: solid;
                border-top-color: #04A4EB;
                margin: 0 auto 40px 0; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .place-order-title {
              position: relative; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .place-order-title h4 {
                font-size: 1.357rem;
                margin: 0;
                margin-bottom: 20px; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .email-container h6 {
              margin-top: 10px;
              font-size: 14px;
              line-height: 25px;
              margin-bottom: 40px; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .email-container h6 .email-link {
                text-decoration: none;
                color: inherit;
                background-color: transparent; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .email-container h6 .amilon-link {
                font-size: 14px; }
                .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .email-container h6 .amilon-link a {
                  text-decoration: none;
                  color: #04A4EB;
                  background-color: transparent; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .directly-by-italy {
              position: relative; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .directly-by-italy h4 {
                font-size: 19px;
                margin: 0;
                margin-bottom: 20px; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .firm-visiters {
              position: relative; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .firm-visiters .firm-visiters-margin {
                font-size: 14px;
                line-height: 25px;
                margin-bottom: 40px; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .spain-link {
              position: relative; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .spain-link h6 {
                margin-top: 10px;
                font-size: 14px;
                line-height: 25px;
                margin-bottom: 40px; }
            .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .btn-homepage-container {
              position: relative; }
              .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .contacts-width-container .contacts-text-container .btn-homepage-container .btn-homepage {
                display: inline-block;
                font-size: 14px;
                text-align: left;
                line-height: 30px;
                height: 30px;
                color: white;
                background-color: #04A4EB;
                padding: 0 10px;
                border-bottom-left-radius: 15px;
                border-top-left-radius: 15px;
                border-bottom-right-radius: 15px;
                border-top-right-radius: 15px;
                margin: 0 auto 0 0; }

.decoration-giftcard-link,
.decoration-giftiamo-link,
.decoration-mitarjetaregalo-link,
.btn-homepage-decoration-link {
  text-decoration: none;
  color: inherit;
  background-color: transparent; }

.decoration-giftcard-link:hover,
.decoration-giftiamo-link:hover,
.decoration-mitarjetaregalo-link:hover,
.btn-homepage-decoration-link:hover {
  text-decoration: none;
  color: inherit;
  background-color: transparent; }

@media screen and (max-width: 1400px) {
  .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside {
    /*padding: 0px;*/ } }

@media screen and (max-width: 767px) {
  .contacts-container .under-contacts-container .contacts-container-margin {
    height: 100%;
    background: white;
    padding-top: 40px;
    margin-top: 50px;
    color: white; }
    .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside {
      display: block;
      margin: 0 !important; }
      .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .title-container .hr-line-sx {
        width: 100px;
        border-top-width: 4.5px;
        border-top-style: solid;
        border-top-color: #04A4EB;
        margin: 0 auto 40px 0; }
      .contacts-container .under-contacts-container .contacts-container-margin .align-items-inside .wrap-contact .under-register .sx .column-contact {
        display: block; }
  .col-sm-5 {
    max-width: 100%; } }

.category-p-wrap {
  display: none;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 90px;
  width: 100%;
  height: calc(100% - 70px);
  background-color: white;
  z-index: 15;
  color: black !important; }
  .category-p-wrap .under-category-p-wrap {
    height: auto;
    border-top: 2px solid #04A4EB; }
    .category-p-wrap .under-category-p-wrap .margin-category-p {
      font-weight: 600;
      max-width: 1024px;
      padding: 20px 15px 0;
      margin: 0 auto;
      margin-bottom: 50px; }
      .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header {
        position: relative;
        margin: 0;
        display: flex; }
        .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-sx {
          float: left;
          width: 50%;
          padding: 0; }
          .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-sx .container-home {
            position: relative;
            direction: inherit;
            text-align: inherit;
            height: auto; }
            .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-sx .container-home .under-container-home {
              font-size: 100em;
              width: auto;
              vertical-align: top;
              min-width: 0px; }
              .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-sx .container-home .under-container-home a {
                text-decoration: none;
                color: inherit;
                cursor: default; }
                .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-sx .container-home .under-container-home a .container-home-padding {
                  display: none;
                  font-size: 15px;
                  cursor: pointer;
                  padding-top: 23px;
                  padding-left: 47px; }
                  .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-sx .container-home .under-container-home a .container-home-padding p {
                    font-weight: 500;
                    margin: 0px;
                    display: inline;
                    font-size: 15px;
                    cursor: pointer; }
                .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-sx .container-home .under-container-home a .container-home-padding:before {
                  content: '';
                  background-image: url(../../../../images/components/icon_home_white.svg);
                  background-size: 40px !important;
                  background-repeat: no-repeat;
                  position: absolute;
                  width: 40px;
                  height: 47px;
                  left: 0px;
                  bottom: -4px; }
        .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-dx {
          float: left;
          width: 50%;
          padding: 0; }
          .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-dx .container-btn-category-p {
            position: relative; }
            .category-p-wrap .under-category-p-wrap .margin-category-p .category-p-header .category-dx .container-btn-category-p .img-close-btn-category-p {
              margin: 0px 0px 0px auto;
              cursor: pointer;
              display: block;
              max-width: 100%;
              height: auto; }
      .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p {
        height: auto;
        margin-top: 50px;
        display: block !important; }
        .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p {
          height: auto;
          padding-right: 15px; }
          .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p .wrap-single-category {
            display: inline-block;
            padding: 0;
            margin-bottom: 20px;
            margin-top: 20px; }
            @media screen and (max-width: 767px) {
              .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p .wrap-single-category {
                margin-bottom: 0; } }
            .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p .wrap-single-category .under-wrap-single-category {
              vertical-align: top; }
              .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p .wrap-single-category .under-wrap-single-category .wrap-single-category-position {
                position: relative; }
                .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p .wrap-single-category .under-wrap-single-category .wrap-single-category-position h2 {
                  height: 50px;
                  font-size: 14px;
                  line-height: 50px;
                  text-align: center;
                  cursor: pointer;
                  padding: 0 20px;
                  border-bottom-style: solid;
                  border-bottom-width: 1px;
                  margin-right: 15px;
                  margin-left: 15px;
                  color: black; }
                  @media screen and (max-width: 767px) {
                    .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p .wrap-single-category .under-wrap-single-category .wrap-single-category-position h2 {
                      text-align: left;
                      line-height: inherit;
                      height: auto;
                      padding: 0 0 6px 7px;
                      padding-left: 7px;
                      margin: 0 0 20px 15px; } }
                  .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p .wrap-single-category .under-wrap-single-category .wrap-single-category-position h2 a {
                    text-decoration: none;
                    color: inherit; }
                    .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p .wrap-single-category .under-wrap-single-category .wrap-single-category-position h2 a span {
                      color: black;
                      display: inline-block;
                      line-height: 1.2;
                      vertical-align: middle; }
                      .category-p-wrap .under-category-p-wrap .margin-category-p .container-category-p .under-container-category-p .wrap-single-category .under-wrap-single-category .wrap-single-category-position h2 a span::before {
                        content: none; }

.mobile-device-searchbox .search-box-category-partial {
  text-align: center; }

.container-contacts-mobile {
  min-width: 0px;
  display: inline-block;
  /*margin-right: 30px;*/ }
  @media screen and (max-width: 337px) {
    .container-contacts-mobile {
      /*margin-right: 20px*/ } }
  .container-contacts-mobile .contact-decoration-mobile {
    color: #007bff;
    text-decoration: none;
    background-color: transparent; }
    .container-contacts-mobile .contact-decoration-mobile .contact-logo-text-mobile {
      font-size: 13px;
      text-align: left;
      line-height: 30px;
      width: 114px;
      height: 30px;
      color: #4a4a4a;
      background-color: #ffffff;
      padding-left: 44px;
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      background-image: url(../../../../images/navbar/icon_contact.svg);
      background-size: 25px;
      background-repeat: no-repeat;
      background-position: 10px center;
      margin-right: 0;
      margin-left: auto;
      display: none; }
  @media screen and (min-width: 767px) {
    .container-contacts-mobile {
      display: none; } }

.container-display-block {
  display: block; }

.card {
  font-size: 1.13rem;
  padding: 0 0 10px 10px;
  border-bottom: solid 1px white;
  margin-bottom: 20px; }

@media screen and (min-width: 767px) {
  .search-box-filter-device,
  .container-search-btn-device {
    display: none; }
  .category-p-wrap {
    display: none !important; }
  .accordion-wrapper {
    display: none; }
  .wrap-home-link {
    display: none; }
  .img-close-btn-category-p {
    display: none !important; }
  .share-btn-nav-mobile {
    display: none; }
  .vis-in-mobile {
    display: none; }
  .vis-in-desktop {
    display: block; }
  .img-close-btn-category-p-desktop {
    cursor: pointer; }
  .title-mobile-category {
    display: none; } }

@media screen and (max-width: 767px) {
  .vis-in-mobile {
    display: block; }
  .vis-in-desktop {
    display: none; }
  .img-close-btn-category-p-desktop {
    display: none; }
  .span-delete-mobile {
    display: none !important; }
  .accordion-wrapper {
    border-top: 1px solid #04A4EB; }
  .container-not-device {
    display: none; }
  .category-p-wrap
.under-category-p-wrap
.margin-category-p
.container-category-p {
    margin-top: 0; }
  .wrap-home-link {
    margin-top: 45px;
    border-top: 1px solid #04A4EB; }
    .wrap-home-link a {
      text-decoration: none;
      color: white; }
      .wrap-home-link a :hover {
        color: white; }
      .wrap-home-link a .home-link {
        background-color: transparent;
        cursor: pointer;
        font-size: 14px;
        padding: 0 0 10px 10px;
        border: 0;
        border-bottom: solid 1px #04A4EB;
        margin-bottom: 20px;
        border-radius: 0; }
        .wrap-home-link a .home-link h2 {
          font-size: 14px; }
  .card {
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    border: 0;
    margin-bottom: 20px;
    border-radius: 0; }
    .card .card-header {
      background-color: transparent;
      padding: 0;
      border-bottom: 0; }
      .card .card-header h2 {
        font-size: 14px; }
    .card .card-body {
      margin-top: 10px;
      padding: 0; }
    .card .btn-link,
    .card .btn-link:hover,
    .card .btn-link:focus {
      color: #04A4EB;
      text-decoration: none; }
    .card .btn-link:hover {
      padding: 0;
      border: 0;
      font-size: 14px;
      text-align: left;
      font: Bold 24px/29px Roboto;
      letter-spacing: 0;
      color: #04A4EB;
      opacity: 1;
      font-family: 'Roboto', sans-serif;
      text-transform: uppercase; }
  .title-mobile-category {
    padding-top: 10px;
    margin: 0;
    font-size: 14px;
    text-align: left;
    font: Bold 24px/29px Roboto;
    letter-spacing: 0;
    color: black;
    opacity: 1;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase; }
  .delete-underline-on-hover:hover {
    text-decoration: none; }
  .title-mobile-catalogue {
    padding-top: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #04A4EB;
    margin-bottom: 5;
    font-size: 14px;
    text-align: left;
    font: Bold 24px/29px Roboto;
    letter-spacing: 0;
    color: black;
    margin: 0;
    opacity: 1;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase; }
  .search-box-filter-device {
    margin-top: 35px;
    width: 70%;
    height: 30px;
    padding: 10px;
    font-size: 14px;
    line-height: 30px;
    background-color: white;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    outline: none;
    border: 1px solid #04A4EB; }
  .container-search-btn-device {
    border-radius: 15px;
    margin-left: -4px;
    background-color: #ffffff;
    height: 30px;
    display: inline-block;
    vertical-align: bottom;
    min-width: 0px;
    width: 50px !important; }
    .container-search-btn-device .btn-search-device {
      border: 1px solid #707070;
      width: 100%;
      height: 30px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      background-color: #04A4EB;
      color: #4a4a4a;
      font-size: 11px;
      border: none;
      outline: none;
      background-image: url(../../../../images/navbar/icon_search.png);
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: 15px center; }
  .position-center-mobile {
    text-align: center;
    color: black !important;
    width: calc(70% + 50px);
    display: inline-flex;
    justify-content: space-between; }
    .position-center-mobile .share-btn-nav-mobile {
      display: inline-block;
      /*margin-right: 30px;*/ } }
    @media screen and (max-width: 767px) and (max-width: 337px) {
      .position-center-mobile .share-btn-nav-mobile {
        /*margin-right: 20px*/ } }

@media screen and (max-width: 767px) {
      .position-center-mobile .share-btn-nav-mobile .share-btn {
        cursor: pointer;
        text-align: center;
        line-height: 30px;
        width: 40px;
        height: 40px;
        color: #4a4a4a;
        background-color: #ffffff;
        background-size: 21px;
        background-repeat: no-repeat;
        background-position: 6px center;
        margin-right: 0;
        vertical-align: middle;
        display: inline-block;
        border: 1px solid #04A4EB;
        border-radius: 28px;
        opacity: 1;
        background-image: url(../../../../images/navbar/share-cat.png); }
    .position-center-mobile .container-contacts-mobile .contact-decoration .contact-logo-text-mob {
      font-family: OpenSans-Semibold;
      text-align: left;
      line-height: 30px;
      width: 40px;
      height: 40px;
      color: #4a4a4a;
      background-color: #ffffff;
      background-image: url(../../../../images/navbar/email-cat.png);
      background-size: 21px;
      background-repeat: no-repeat;
      background-position: 9px center;
      margin-right: 0;
      vertical-align: middle;
      display: inline-block;
      border: 1px solid #04A4EB;
      border-radius: 28px;
      opacity: 1; } }

[data-pagename="home"] {
  margin-top: 0 !important; }

.wrap-homepage {
  position: relative; }
  .wrap-homepage .under-wrap-homepage {
    height: auto; }
    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin {
      position: relative;
      display: table;
      width: 100%;
      height: calc(80vh - 70.4px);
      /*margin-top: 70.4px;*/ }
      .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding {
        display: table-cell;
        vertical-align: middle;
        height: auto; }
        .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .homepage-img-background {
          background: transparent linear-gradient(177deg, #5BC0CA 0%, #086F92 62%, #D73E6E 100%) 0% 0% no-repeat padding-box;
          opacity: 1;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top center;
          position: fixed;
          width: 100%;
          height: calc(100vh - 70.4px);
          top: 70px;
          left: 0; }
          .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .homepage-img-background .title-homepage {
            position: relative;
            text-align: center;
            font: Regular 40px/48px Roboto;
            letter-spacing: 0;
            color: #FFFFFF;
            opacity: 1;
            z-index: 9999;
            top: 10%;
            padding: 10px;
            font-size: 40px; }
        .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-homepage-img-log {
          position: relative; }
          .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-homepage-img-log .homepage-img-log {
            vertical-align: middle;
            display: block;
            height: auto;
            max-width: 30rem;
            margin: 0 auto;
            border: 0; }
        .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .title-homepage {
          text-align: center;
          font: Regular 40px/48px Roboto;
          letter-spacing: 0;
          color: #FFFFFF;
          opacity: 1;
          z-index: 9999; }
        .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage {
          position: absolute;
          top: calc(50% - (263px / 2));
          z-index: 9;
          width: 12rem; }
          .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage {
            height: auto; }
            .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories {
              height: auto; }
              .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a {
                text-decoration: none;
                color: inherit;
                background-color: transparent; }
                .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories {
                  background-color: #4a4a4a;
                  margin-bottom: 37px;
                  padding-left: 40px;
                  padding-top: 30px;
                  background: #FFFFFF 0% 0% no-repeat padding-box;
                  border-radius: 0px 100px 100px 0px;
                  opacity: 1;
                  height: 112px;
                  width: 295px;
                  margin: 0 auto 20px; }
                  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories .container-text-home-menu {
                    height: auto; }
                    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories .container-text-home-menu .little-title {
                      text-align: left;
                      font: Regular 18px/22px Roboto;
                      letter-spacing: 0;
                      color: #333333;
                      opacity: 1;
                      font-family: 'Roboto', sans-serif; }
                    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories .container-text-home-menu .big-title {
                      position: relative;
                      font-size: 9px;
                      color: #d7d7d7; }
                      .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories .container-text-home-menu .big-title h2 {
                        text-align: left;
                        font: Bold 24px/29px Roboto;
                        letter-spacing: 0;
                        color: #333333;
                        opacity: 1;
                        font-family: 'Roboto', sans-serif; }
                .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories-retailer {
                  background-color: #4a4a4a;
                  margin-bottom: 37px;
                  padding-left: 40px;
                  padding-top: 40px;
                  background: #FFFFFF 0% 0% no-repeat padding-box;
                  border-radius: 0px 100px 100px 0px;
                  opacity: 1;
                  height: 112px;
                  width: 295px;
                  margin: 0 auto 20px; }
                  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories-retailer .container-text-home-menu {
                    height: auto; }
                    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories-retailer .container-text-home-menu .little-title {
                      text-align: left;
                      font: Regular 18px/22px Roboto;
                      letter-spacing: 0;
                      color: #333333;
                      opacity: 1;
                      font-family: 'Roboto', sans-serif; }
                    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories-retailer .container-text-home-menu .big-title {
                      position: relative;
                      font-size: 9px;
                      color: #d7d7d7; }
                      .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories-retailer .container-text-home-menu .big-title h2 {
                        text-align: left;
                        font: Bold 24px/29px Roboto;
                        letter-spacing: 0;
                        color: #333333;
                        opacity: 1;
                        font-family: 'Roboto', sans-serif; }
                .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .styling-pdf {
                  background-color: #4a4a4a;
                  margin-bottom: 37px;
                  padding-left: 30px;
                  padding-top: 30px;
                  background: #333333 0% 0% no-repeat padding-box;
                  border-radius: 0px 100px 100px 0px;
                  opacity: 1;
                  height: 112px;
                  width: 295px; }
                  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .styling-pdf .pdf-img {
                    display: inline-block;
                    margin-bottom: 32px;
                    margin-right: 15px; }
                  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .styling-pdf h2 {
                    color: white !important;
                    display: inline-block; }
              .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-catalogue-download {
                height: 6rem;
                padding-left: 20px;
                background-color: #4a4a4a;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                margin-bottom: 10px; }
                .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-catalogue-download .container-text-home-menu {
                  height: auto; }
                  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-catalogue-download .container-text-home-menu .little-title {
                    text-align: left;
                    font: Regular 18px/22px;
                    letter-spacing: 0;
                    color: #333333;
                    opacity: 1;
                    font-family: 'Roboto', sans-serif; }
                  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-catalogue-download .container-text-home-menu .big-title {
                    position: relative;
                    font-size: 9px;
                    color: #d7d7d7; }
                    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-catalogue-download .container-text-home-menu .big-title h2 {
                      font-family: OpenSans-Semibold;
                      font-size: 19px;
                      color: #ffffff;
                      margin-bottom: 0;
                      margin-top: 10px; }
        .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-img-homepage-giftcard {
          text-align: center; }
          .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-img-homepage-giftcard .img-homepage-giftcard {
            position: relative;
            top: 70px; }

@media screen and (max-width: 1024px) {
  .img-homepage-giftcard {
    position: relative !important;
    left: 40px !important; }
  .grecaptcha-badge {
    bottom: 0 !important; } }

@media screen and (max-width: 900px) {
  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin {
    height: 0; }
    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding {
      height: auto;
      padding: 30px 20px;
      margin-top: 56.2px; }
      .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-homepage-img-log {
        margin-bottom: 30px; }
        .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-homepage-img-log .homepage-img-log {
          position: relative;
          max-width: 16rem; }
      .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage {
        position: relative;
        top: 120px;
        width: auto;
        text-align: center;
        margin-top: 0 !important; }
        .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-all-giftcards-categories {
          border-radius: 100px 100px 100px 100px !important;
          padding-left: 0 !important;
          height: 80px !important;
          padding-top: 16px !important;
          margin-bottom: 20px !important;
          width: 235px !important; }
          .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-all-giftcards-categories .little-title {
            text-align: center !important; }
          .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-all-giftcards-categories .big-title h2 {
            text-align: center !important; }
        .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-all-giftcards-categories-retailer {
          border-radius: 100px 100px 100px 100px !important;
          padding-left: 0px !important;
          height: 80px !important;
          padding-top: 25px !important;
          margin-bottom: 20px !important;
          width: 235px !important; }
          .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .padding-all-giftcards-categories-retailer .container-text-home-menu .big-title h2 {
            text-align: center !important; }
        .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories .styling-pdf {
          padding-top: 10px !important; }
      .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-img-homepage-giftcard .img-homepage-giftcard {
        position: relative !important;
        top: 100px !important;
        z-index: 1;
        left: 0 !important; }
      .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .title-homepage {
        font-size: 20px !important;
        margin-top: -25px; } }

@media screen and (max-width: 371px) {
  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage {
    position: relative;
    top: 50px;
    width: auto; } }

@media screen and (max-height: 500px) {
  .wrap-homepage
.under-wrap-homepage
.wrap-homepage-margin
.homepage-padding
.homepage-img-background {
    background: unset;
    position: unset; }
    .wrap-homepage
.under-wrap-homepage
.wrap-homepage-margin
.homepage-padding
.homepage-img-background .title-homepage {
      top: 40%; } }

@media screen and (max-height: 1100px) {
  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories {
    background-color: #4a4a4a;
    margin-bottom: 37px;
    padding-left: 20px;
    padding-top: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0px 100px 100px 0px;
    opacity: 1;
    height: 80px;
    width: 225px;
    margin: 0 auto 20px;
    padding-bottom: 20px; }
  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories-retailer {
    background-color: #4a4a4a;
    margin-bottom: 37px;
    padding-left: 20px;
    padding-top: 26px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0px 100px 100px 0px;
    opacity: 1;
    height: 80px;
    width: 225px;
    margin: 0 auto 20px;
    padding-bottom: 20px; }
  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .styling-pdf {
    background-color: #4a4a4a;
    margin-bottom: 37px;
    padding-left: 20px;
    padding-top: 10px;
    background: #333333 0% 0% no-repeat padding-box;
    border-radius: 0px 100px 100px 0px;
    opacity: 1;
    height: 80px;
    width: 225px; }
  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage {
    margin-top: 60px; }
  .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .homepage-img-background .title-homepage {
    font-size: 30px; } }

.sharing-partial input.form-control:-webkit-autofill,
.sharing-partial input.form-control:-webkit-autofill:hover,
.sharing-partial input.form-control:-webkit-autofill:focus,
.sharing-partial input.form-control:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s; }

.sharing-partial {
  position: fixed;
  color: #333333;
  z-index: 99999;
  top: 5%;
  left: 30%;
  width: 40%;
  height: fit-content;
  background: white;
  align-items: center;
  flex-flow: row wrap;
  border: 3px solid #04A4EB; }
  .sharing-partial .container-header-shareP {
    height: auto;
    position: relative;
    margin: 0;
    display: flex;
    padding: 5px;
    top: 20px; }
    .sharing-partial .container-header-shareP .under-container-shareP-dx .close-btn {
      text-align: right;
      color: #333333; }
      .sharing-partial .container-header-shareP .under-container-shareP-dx .close-btn .img-close-btn {
        cursor: pointer;
        max-width: 110px;
        margin: 0px 10px 0px auto; }
  .sharing-partial .wrap-share-field {
    padding: 15px; }
    .sharing-partial .wrap-share-field .share-div-of-label,
    .sharing-partial .wrap-share-field .share-div-of-label-messagebox {
      margin-bottom: 30px; }
      .sharing-partial .wrap-share-field .share-div-of-label .share-label,
      .sharing-partial .wrap-share-field .share-div-of-label-messagebox .share-label {
        color: #333333;
        margin: 0;
        display: block;
        opacity: 1;
        font-size: 16px;
        padding: 0 0 10px 10px; }
      .sharing-partial .wrap-share-field .share-div-of-label .form-group,
      .sharing-partial .wrap-share-field .share-div-of-label-messagebox .form-group {
        box-sizing: border-box;
        width: 100%;
        min-height: 44px;
        margin: 0px;
        padding: 8px;
        border: 1px solid #707070;
        font-family: inherit;
        font-size: 1rem;
        font-weight: normal;
        color: #333333;
        background: transparent;
        border-radius: 10px;
        box-shadow: none; }
      .sharing-partial .wrap-share-field .share-div-of-label .message-text-share,
      .sharing-partial .wrap-share-field .share-div-of-label-messagebox .message-text-share {
        height: 100px; }
    .sharing-partial .wrap-share-field .type-error-message {
      margin-top: -30px;
      margin-bottom: 25px;
      color: red; }
  .sharing-partial .share-div-of-label-privacy {
    display: flex; }
    .sharing-partial .share-div-of-label-privacy .btn-privacy {
      width: auto;
      margin-top: 5px; }
    .sharing-partial .share-div-of-label-privacy .share-label-margin {
      margin-left: 10px; }
    .sharing-partial .share-div-of-label-privacy .share-label {
      font-size: 16px;
      color: #333333; }
  .sharing-partial .share-btn-nav {
    margin-bottom: 15px;
    width: 100%;
    text-align: center; }
  .sharing-partial #privacy-error-message {
    color: red;
    bottom: 20px; }
    .sharing-partial #privacy-error-message .type-error-message {
      margin-top: -15px; }
  .sharing-partial #message-error-message {
    color: red; }
    .sharing-partial #message-error-message .type-error-message {
      margin-top: 0; }

@media screen and (max-width: 767px) {
  .sharing-partial {
    overflow-y: scroll;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100vh; } }

@media screen and (max-height: 900px) {
  .sharing-partial {
    top: 30%;
    bottom: 30%; }
  .sharing-partial-email-send {
    overflow-y: unset;
    top: auto;
    bottom: auto; } }

.text-light {
  color: #333333 !important; }

html,
body {
  height: calc(100% - 70px); }

body i {
  font-style: normal;
  font-size: 23px;
  font-family: 'FontAwesome' !important; }

/*html{
    height:100%
}*/
#pagename {
  margin: 135px auto 0;
  /*margin-bottom: 60px;*/ }
  @media screen and (max-width: 767px) {
    #pagename {
      margin: 90px auto 0; } }

.notes {
  margin-left: 30px;
  margin-right: 30px; }
  .notes h6 {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    font: Bold 16px/20px Roboto;
    letter-spacing: 0;
    color: #333333;
    opacity: 1; }

.mrg-left {
  margin-left: 30px;
  margin-right: 30px; }

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 111;
  cursor: pointer; }

.overlay-display-block {
  display: block !important; }

body {
  background: white;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #4a4a4a;
  background-attachment: fixed !important;
  overflow-y: scroll;
  /*margin-bottom:70px;*/ }

.body-content {
  position: relative;
  max-width: 1024px;
  padding: 35px 15px 30px 15px;
  margin: 0px auto 0;
  box-sizing: content-box; }
  @media screen and (max-width: 1066px) {
    .body-content {
      box-sizing: border-box;
      /*padding-bottom: 100px;*/ } }
  .body-content .back-to-top {
    display: inline-block;
    background-color: #04A4EB;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    left: 30px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000; }
  .body-content .back-to-top::after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 2em;
    line-height: 50px;
    color: #fff; }
  .body-content .back-to-top:hover {
    cursor: pointer;
    background-color: #04A4EB; }
  .body-content .back-to-top:active {
    background-color: #04A4EB; }
  .body-content .back-to-top.show {
    opacity: 1;
    visibility: visible; }

.body-content-fixed {
  overflow: hidden; }

.margin-mobile-body-content {
  max-width: 1024px;
  padding: 30px 15px;
  margin: 125.4px auto 0; }

.body-content-home {
  max-width: 1024px;
  padding: 30px 5px;
  margin: 125.4px auto 0; }

/*.pdf-download-btn-circle {
    text-align: center;
    padding: 7px;
    width: 81px;
    height: 81px;
    background-color: map-get($colors, primary-btns);
    top: 50%;
    left: 0%;
    position: fixed;
    color: white;
    cursor: pointer;


    img {
        width: 30px;
    }

    p {
        margin: 0px;
        text-transform: uppercase;
        font-size: 12px;
    }
}*/
.card-general .container-img {
  padding: 15px 10px; }
  .card-general .container-img .img-border-radius {
    width: 100%;
    height: 115px;
    object-fit: cover; }

.card-general .card-body-padding {
  line-height: 40px;
  width: 100%;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  padding: 0px; }
  .card-general .card-body-padding .title-category {
    display: inline-block;
    vertical-align: middle;
    font-family: OpenSans-Semibold;
    text-align: center;
    font-size: 1rem;
    line-height: 1;
    color: #4a4a4a;
    width: 100%;
    padding: 0 15px;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .card-general .card-body-padding .category-name {
    font-family: OpenSans-Semibold;
    text-align: center;
    font-size: 14px;
    line-height: 1;
    color: #4a4a4a;
    font-weight: 600;
    text-align: center;
    font: Bold 14px/15px Roboto;
    letter-spacing: 0;
    color: #333333;
    opacity: 1; }

.card-general:hover {
  transform: scale(1.05);
  transition-duration: 0.5s;
  cursor: pointer; }
  .card-general:hover .card-body-padding {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }

button,
.button {
  cursor: pointer; }

.primary-btn {
  background: #04A4EB;
  border-radius: 18px;
  border: none;
  padding: 5px 40px;
  color: white;
  cursor: pointer; }

.footer-style {
  width: 100%;
  height: 70px;
  text-align: center;
  border-top: 1px solid #707070;
  /*position: absolute;
    bottom: 0;*/ }
  .footer-style > p {
    margin-top: 10px; }

@media screen and (min-width: 1025px) {
  .download-pdf,
  .download-exc {
    display: none; } }

@media screen and (max-width: 767px) {
  .pdf-download-btn-circle,
  .exc-download-btn-circle {
    cursor: pointer;
    font-family: OpenSans-Semibold;
    text-align: left;
    line-height: 30px;
    width: 40px;
    height: 40px;
    background-image: url(../../../../images/navbar/download.png);
    background-size: 21px;
    background-repeat: no-repeat;
    background-position: 9px center;
    margin-right: 0;
    vertical-align: middle;
    display: inline-block;
    border: 1px solid #04A4EB;
    border-radius: 28px;
    opacity: 1; }
  .container-dwn-pdf,
  .container-dwn-exc {
    display: none; } }

@media screen and (max-width: 372px) {
  .pdf-download-btn-circle p,
  .exc-download-btn-circle p {
    font-size: 16px;
    display: inline-block; } }

@media screen and (min-width: 767px) {
  .mobile-device-searchbox {
    display: none; }
  /*.filter-partial{
        display:none;
    }*/ }

@media screen and (max-width: 767px) {
  .mobile-device-searchbox {
    margin: 0;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px; } }

.download-pdf,
.download-exc {
  display: inline-block; }

.color-btn-email-send {
  background-color: #04A4EB;
  border-color: #04A4EB; }

.main-container {
  min-height: calc(100vh + 45px);
  margin-bottom: 70px; }

@font-face {
  font-family: 'OpenSans-Light';
  src: url("../fonts/opensans-light-webfont.woff2") format("woff2"), url("../fonts/opensans-light-webfont.woff") format("woff"), url("../fonts/opensans-light-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-Semibold';
  src: url("../fonts/opensans-semibold-webfont.woff2") format("woff2"), url("../fonts/opensans-semibold-webfont.woff") format("woff"), url("../fonts/opensans-semibold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonLight';
  src: url("../fonts/karbon-light-webfont.woff2") format("woff2"), url("../fonts/karbon-light-webfont.woff") format("woff"), url("../fonts/karbon-light-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonRegular';
  src: url("../fonts/karbon-regular-webfont.woff2") format("woff2"), url("../fonts/karbon-regular-webfont.woff") format("woff"), url("../fonts/karbon-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonMedium';
  src: url("../fonts/karbon-medium-webfont.woff2") format("woff2"), url("../fonts/karbon-medium-webfont.woff") format("woff"), url("../fonts/karbon-medium-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonSemiBold';
  src: url("../fonts/karbon-semibold-webfont.woff2") format("woff2"), url("../fonts/karbon-semibold-webfont.woff") format("woff"), url("../fonts/karbon-semibold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonBold';
  src: url("../fonts/karbon-bold-webfont.woff2") format("woff2"), url("../fonts/karbon-bold-webfont.woff") format("woff"), url("../fonts/karbon-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.selectric-hide-select + .selectric {
  display: none !important; }

.selectric-items {
  width: 50% !important;
  height: auto !important;
  right: 0;
  left: inherit; }
  .selectric-items li {
    font-size: 14px;
    text-transform: capitalize; }

.ico {
  background-size: 28px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  height: 30px;
  width: 30px;
  margin: 0 6px 0 0;
  /*    &.ico-it-IT {
        background-image: url(../../../../images/flags/it-IT-circle.png);
    }*/ }
  .ico.ico-it-IT {
    background-image: url(../../../../images/flags/map.png); }
  .ico.ico-en-GB {
    background-image: url(../../../../images/flags/en-GB-circle.png); }
  .ico.ico-es-ES {
    background-image: url(../../../../images/flags/es-ES-circle.png); }

ul {
  text-align: left; }

.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
  background-color: transparent !important;
  color: black !important; }

.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
  background-color: transparent !important;
  color: black !important; }

.irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before {
  display: none !important; }

.irs--round .irs-handle {
  top: 24px;
  width: 27px;
  height: 27px;
  background-color: #04A4EB !important;
  border-radius: 24px;
  border: #04A4EB !important;
  background-image: url(../../../../images/retailer/double-arrow.png) !important;
  background: no-repeat;
  background-position: center;
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .irs--round .irs-handle {
      top: 20px !important;
      width: 37px !important;
      height: 37px !important;
      background-color: #04A4EB !important;
      border-radius: 24px;
      border: #04A4EB !important;
      background-image: url(../../../../images/retailer/double-arrow.png) !important;
      background: no-repeat;
      background-position: center; } }

.irs--round .irs-bar {
  background-color: #C4C4C4; }

.irs--round .irs-line {
  top: 42px;
  height: 4px;
  background-color: #C4C4C4;
  border-radius: 4px; }

.irs {
  bottom: 6px !important; }

.btn-light {
  color: #212529 !important;
  background-color: transparent !important;
  /* border-color: #d3d9df; */
  border-radius: 50px !important; }

.dropdown-toggle.bs-placeholder {
  color: black !important;
  background-color: transparent !important;
  border-radius: 50px !important; }

.tooltip-inner {
  background-color: white;
  border: 1px solid #04A4EB;
  color: #04A4EB; }

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #04A4EB; }

.gooey {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  background: transparent;
  z-index: 112; }

.dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  background: #D73E6E;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite; }

.dots {
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
  /*span:nth-child(1) {
        background: #5BC0CA;
    }

    span:nth-child(2) {
        background: #086F92;
    }

    span:nth-child(3) {
        background: #D73E6E;
    }*/ }
  .dots span {
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    border-radius: 50%;
    background: #2BA2D5; }

@keyframes dot {
  50% {
    transform: translateX(96px); } }

@keyframes dots {
  50% {
    transform: translateX(-31px); } }
