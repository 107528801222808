﻿[data-pagename="home"] {
    margin-top: 0 !important;
}
.wrap-homepage {
    position: relative;

    .under-wrap-homepage {
        height: auto;

        .wrap-homepage-margin {
            position: relative;
            display: table;
            width: 100%;
            height: calc(80vh - 70.4px);
            /*margin-top: 70.4px;*/
            .homepage-padding {
                display: table-cell;
                vertical-align: middle;
                height: auto;

                .homepage-img-background {
                    background: transparent linear-gradient(177deg, #5BC0CA 0%, #086F92 62%, #D73E6E 100%) 0% 0% no-repeat padding-box;
                    opacity: 1;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: top center;
                    position: fixed;
                    width: 100%;
                    height: calc(100vh - 70.4px);
                    top: 70px;
                    left: 0;

                    .title-homepage {
                        position: relative;
                        text-align: center;
                        font: Regular 40px/48px Roboto;
                        letter-spacing: 0;
                        color: #FFFFFF;
                        opacity: 1;
                        z-index: 9999;
                        top: 10%;
                        padding: 10px;
                        font-size: 40px;
                    }
                }

                .wrap-homepage-img-log {
                    position: relative;

                    .homepage-img-log {
                        vertical-align: middle;
                        display: block;
                        height: auto;
                        max-width: 30rem;
                        margin: 0 auto;
                        border: 0;
                    }
                }

                .title-homepage {
                    text-align: center;
                    font: Regular 40px/48px Roboto;
                    letter-spacing: 0;
                    color: #FFFFFF;
                    opacity: 1;
                    z-index: 9999;
                }

                .wrap-menu-homepage {
                    position: absolute;
                    top: calc(50% - (263px / 2));
                    z-index: 9;
                    width: 12rem;

                    .under-wrap-menu-homepage {
                        height: auto;

                        .all-giftcards-categories {
                            height: auto;

                            a {
                                text-decoration: none;
                                color: inherit;
                                background-color: transparent;

                                .padding-all-giftcards-categories {
                                    background-color: #4a4a4a;
                                    margin-bottom: 37px;
                                    padding-left: 40px;
                                    padding-top: 30px;
                                    background: #FFFFFF 0% 0% no-repeat padding-box;
                                    border-radius: 0px 100px 100px 0px;
                                    opacity: 1;
                                    height: 112px;
                                    width: 295px;
                                    margin: 0 auto 20px;

                                    .container-text-home-menu {
                                        height: auto;

                                        .little-title {
                                            text-align: left;
                                            font: Regular 18px/22px Roboto;
                                            letter-spacing: 0;
                                            color: #333333;
                                            opacity: 1;
                                            font-family: 'Roboto', sans-serif;
                                        }

                                        .big-title {
                                            position: relative;
                                            font-size: 9px;
                                            color: #d7d7d7;

                                            h2 {
                                                text-align: left;
                                                font: Bold 24px/29px Roboto;
                                                letter-spacing: 0;
                                                color: #333333;
                                                opacity: 1;
                                                font-family: 'Roboto', sans-serif;
                                            }
                                        }
                                    }
                                }

                                .padding-all-giftcards-categories-retailer {
                                    background-color: #4a4a4a;
                                    margin-bottom: 37px;
                                    padding-left: 40px;
                                    padding-top: 40px;
                                    background: #FFFFFF 0% 0% no-repeat padding-box;
                                    border-radius: 0px 100px 100px 0px;
                                    opacity: 1;
                                    height: 112px;
                                    width: 295px;
                                    margin: 0 auto 20px;

                                    .container-text-home-menu {
                                        height: auto;

                                        .little-title {
                                            text-align: left;
                                            font: Regular 18px/22px Roboto;
                                            letter-spacing: 0;
                                            color: #333333;
                                            opacity: 1;
                                            font-family: 'Roboto', sans-serif;
                                        }

                                        .big-title {
                                            position: relative;
                                            font-size: 9px;
                                            color: #d7d7d7;

                                            h2 {
                                                text-align: left;
                                                font: Bold 24px/29px Roboto;
                                                letter-spacing: 0;
                                                color: #333333;
                                                opacity: 1;
                                                font-family: 'Roboto', sans-serif;
                                            }
                                        }
                                    }
                                }

                                .styling-pdf {
                                    background-color: #4a4a4a;
                                    margin-bottom: 37px;
                                    padding-left: 30px;
                                    padding-top: 30px;
                                    background: #333333 0% 0% no-repeat padding-box;
                                    border-radius: 0px 100px 100px 0px;
                                    opacity: 1;
                                    height: 112px;
                                    width: 295px;

                                    .pdf-img {
                                        display: inline-block;
                                        margin-bottom: 32px;
                                        margin-right: 15px;
                                    }

                                    h2 {
                                        color: white !important;
                                        display: inline-block;
                                    }
                                }
                            }



                            .padding-catalogue-download {
                                height: 6rem;
                                padding-left: 20px;
                                background-color: #4a4a4a;
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                                margin-bottom: 10px;

                                .container-text-home-menu {
                                    height: auto;

                                    .little-title {
                                        text-align: left;
                                        font: Regular 18px/22px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        opacity: 1;
                                        font-family: 'Roboto', sans-serif;
                                    }

                                    .big-title {
                                        position: relative;
                                        font-size: 9px;
                                        color: #d7d7d7;

                                        h2 {
                                            font-family: OpenSans-Semibold;
                                            font-size: 19px;
                                            color: #ffffff;
                                            margin-bottom: 0;
                                            margin-top: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .wrap-img-homepage-giftcard {
                    text-align: center;

                    .img-homepage-giftcard {
                        position: relative;
                        top: 70px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .img-homepage-giftcard {
        position: relative !important;
        left: 40px !important;
    }
    .grecaptcha-badge {
        bottom: 0 !important;
    }
}

@media screen and (max-width: 900px) {

    .wrap-homepage {
        .under-wrap-homepage {
            .wrap-homepage-margin {
                height: 0;

                .homepage-padding {
                    height: auto;
                    padding: 30px 20px;
                    margin-top: 56.2px;

                    .wrap-homepage-img-log {
                        margin-bottom: 30px;

                        .homepage-img-log {
                            position: relative;
                            max-width: 16rem;
                        }
                    }

                    .wrap-menu-homepage {
                        position: relative;
                        top: 120px;
                        width: auto;
                        text-align:center;
                        margin-top: 0 !important;
                        .under-wrap-menu-homepage {
                            .all-giftcards-categories {
                                .padding-all-giftcards-categories {
                                    border-radius: 100px 100px 100px 100px !important;
                                    padding-left: 0 !important;
                                    height: 80px !important;
                                    padding-top: 16px !important;
                                    margin-bottom: 20px !important;
                                    width: 235px !important;

                                    .little-title {
                                        text-align: center !important;
                                    }

                                    .big-title {
                                        h2 {
                                            text-align: center !important;
                                        }
                                    }
                                }

                                .padding-all-giftcards-categories-retailer {
                                    border-radius: 100px 100px 100px 100px !important;
                                    padding-left: 0px !important;
                                    height: 80px !important;
                                    padding-top: 25px !important;
                                    margin-bottom: 20px !important;
                                    width: 235px !important;





                                    .container-text-home-menu {
                                        .big-title {
                                            h2 {
                                                text-align: center !important;
                                            }
                                        }
                                    }
                                }

                                .styling-pdf {
                                    padding-top: 10px !important;
                                }
                            }
                        }
                    }

                    .wrap-img-homepage-giftcard {
                        .img-homepage-giftcard {
                            position: relative !important;
                            top: 100px !important;
                            z-index:1;
                            left:0 !important;
                        }
                    }

                    .title-homepage {
                        font-size: 20px !important;
                        margin-top: -25px;
                    }
                }
            }
        }
    }
    
}

@media screen and (max-width: 371px){

   

    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage {
        position: relative;
        top: 50px;
        width: auto;
    }
    
}

@media screen and (max-height: 500px) {
    .wrap-homepage
    .under-wrap-homepage
    .wrap-homepage-margin
    .homepage-padding
    .homepage-img-background {
        background:unset;
        position:unset;
        .title-homepage {
            top: 40%;
        }
    }
    
}

@media screen and (max-height: 1100px) {


    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories {
        background-color: #4a4a4a;
        margin-bottom: 37px;
        padding-left: 20px;
        padding-top: 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 0px 100px 100px 0px;
        opacity: 1;
        height: 80px;
        width: 225px;
        margin: 0 auto 20px;
        padding-bottom: 20px;
    }

    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .padding-all-giftcards-categories-retailer {
        background-color: #4a4a4a;
        margin-bottom: 37px;
        padding-left: 20px;
        padding-top: 26px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 0px 100px 100px 0px;
        opacity: 1;
        height: 80px;
        width: 225px;
        margin: 0 auto 20px;
        padding-bottom: 20px;
    }

    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage .under-wrap-menu-homepage .all-giftcards-categories a .styling-pdf {
        background-color: #4a4a4a;
        margin-bottom: 37px;
        padding-left: 20px;
        padding-top: 10px;
        background: #333333 0% 0% no-repeat padding-box;
        border-radius: 0px 100px 100px 0px;
        opacity: 1;
        height: 80px;
        width: 225px;
    }


    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .wrap-menu-homepage {
        margin-top: 60px
    }

    .wrap-homepage .under-wrap-homepage .wrap-homepage-margin .homepage-padding .homepage-img-background .title-homepage{
        font-size: 30px;
    }
}