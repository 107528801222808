﻿.category-p-wrap {
    display: none;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    top: 90px;
    width: 100%;
    height: calc(100% - 70px);
    background-color: white;
    z-index: 15;
    color: black !important;

    .under-category-p-wrap {
        height: auto;
        border-top: 2px solid #04A4EB;

        .margin-category-p {
            font-weight: 600;
            max-width: 1024px;
            padding: 20px 15px 0;
            margin: 0 auto;
            margin-bottom: 50px;

            .category-p-header {
                position: relative;
                margin: 0;
                display: flex;

                .category-sx {
                    float: left;
                    width: 50%;
                    padding: 0;

                    .container-home {
                        position: relative;
                        direction: inherit;
                        text-align: inherit;
                        height: auto;

                        .under-container-home {
                            font-size: 100em;
                            width: auto;
                            vertical-align: top;
                            min-width: 0px;

                            a {
                                text-decoration: none;
                                color: inherit;
                                cursor: default;

                                .container-home-padding {
                                    display: none;
                                    font-size: 15px;
                                    cursor: pointer;
                                    padding-top: 23px;
                                    padding-left: 47px;

                                    p {
                                        font-weight: 500;
                                        margin: 0px;
                                        display: inline;
                                        font-size: 15px;
                                        cursor: pointer;
                                    }
                                }

                                .container-home-padding:before {
                                    content: '';
                                    background-image: url(../../../../images/components/icon_home_white.svg);
                                    background-size: 40px !important;
                                    background-repeat: no-repeat;
                                    position: absolute;
                                    width: 40px;
                                    height: 47px;
                                    left: 0px;
                                    bottom: -4px;
                                }
                            }
                        }
                    }
                }

                .category-dx {
                    float: left;
                    width: 50%;
                    padding: 0;

                    .container-btn-category-p {
                        position: relative;

                        .img-close-btn-category-p {
                            margin: 0px 0px 0px auto;
                            cursor: pointer;
                            display: block;
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .container-category-p {
                height: auto;
                margin-top: 50px;
                display: block !important;

                .under-container-category-p {
                    height: auto;
                    padding-right: 15px;

                    .wrap-single-category {
                        display: inline-block;
                        padding: 0;
                        margin-bottom: 20px;
                        margin-top: 20px;

                        @media screen and (max-width: 767px) {
                            margin-bottom: 0;
                        }

                        .under-wrap-single-category {
                            vertical-align: top;

                            .wrap-single-category-position {
                                position: relative;

                                h2 {
                                    height: 50px;
                                    font-size: 14px;
                                    line-height: 50px;
                                    text-align: center;
                                    cursor: pointer;
                                    padding: 0 20px;
                                    border-bottom-style: solid;
                                    border-bottom-width: 1px;
                                    margin-right: 15px;
                                    margin-left: 15px;
                                    color: black;

                                    @media screen and (max-width: 767px) {
                                        text-align: left;
                                        line-height: inherit;
                                        height: auto;
                                        padding: 0 0 6px 7px;
                                        padding-left: 7px;
                                        margin: 0 0 20px 15px;
                                    }


                                    a {
                                        text-decoration: none;
                                        color: inherit;

                                        span {
                                            color: black;
                                            display: inline-block;
                                            line-height: 1.2;
                                            vertical-align: middle;

                                            &::before {
                                                content: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-device-searchbox {
    .search-box-category-partial {
        text-align: center;
    }
}

.container-contacts-mobile {
    min-width: 0px;
    display: inline-block;
    /*margin-right: 30px;*/

    @media screen and (max-width: 337px) {
        /*margin-right: 20px*/
    }

    .contact-decoration-mobile {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;

        .contact-logo-text-mobile {
            font-size: 13px;
            text-align: left;
            line-height: 30px;
            width: 114px;
            height: 30px;
            color: #4a4a4a;
            background-color: #ffffff;
            padding-left: 44px;
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;
            background-image: url(../../../../images/navbar/icon_contact.svg);
            background-size: 25px;
            background-repeat: no-repeat;
            background-position: 10px center;
            margin-right: 0;
            margin-left: auto;
            display: none;
        }
    }

    @media screen and (min-width: 767px) {
        display: none;
    }
}

.container-display-block {
    display: block;
}

.card {
    font-size: 1.13rem;
    padding: 0 0 10px 10px;
    border-bottom: solid 1px white;
    margin-bottom: 20px;
}

@media screen and (min-width: 767px) {
    .search-box-filter-device,
    .container-search-btn-device {
        display: none;
    }

    .category-p-wrap {
        display: none !important;
    }

    .accordion-wrapper {
        display: none;
    }

    .wrap-home-link {
        display: none;
    }

    .img-close-btn-category-p {
        display: none !important;
    }

    .share-btn-nav-mobile {
        display: none;
    }

    .vis-in-mobile {
        display: none;
    }

    .vis-in-desktop {
        display: block;
    }

    .img-close-btn-category-p-desktop {
        cursor: pointer;
    }

    .title-mobile-category {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .vis-in-mobile {
        display: block;
    }

    .vis-in-desktop {
        display: none;
    }

    .img-close-btn-category-p-desktop {
        display: none;
    }

    .span-delete-mobile {
        display: none !important;
    }

    .accordion-wrapper {
        border-top: 1px solid #04A4EB;
    }

    .container-not-device {
        display: none;
    }

    .category-p-wrap
    .under-category-p-wrap
    .margin-category-p
    .container-category-p {
        margin-top: 0;
    }

    .wrap-home-link {
        margin-top: 45px;
        border-top: 1px solid #04A4EB;

        a {
            text-decoration: none;
            color: white;

            & :hover {
                color: white;
            }

            .home-link {
                background-color: transparent;
                cursor: pointer;
                font-size: 14px;
                padding: 0 0 10px 10px;
                border: 0;
                border-bottom: solid 1px #04A4EB;
                margin-bottom: 20px;
                border-radius: 0;


                h2 {
                    font-size: 14px;
                }
            }
        }
    }


    .card {
        background-color: transparent;
        cursor: pointer;
        font-size: 14px;
        padding: 0;
        border: 0;
        margin-bottom: 20px;
        border-radius: 0;

        .card-header {
            background-color: transparent;
            padding: 0;
            border-bottom: 0;

            h2 {
                font-size: 14px;
            }
        }

        .card-body {
            margin-top: 10px;
            padding: 0;
        }

        .btn-link,
        .btn-link:hover,
        .btn-link:focus {
            color: #04A4EB;
            text-decoration: none;
        }



        .btn-link:hover {
            padding: 0;
            border: 0;
            font-size: 14px;
            text-align: left;
            font: Bold 24px/29px Roboto;
            letter-spacing: 0;
            color: #04A4EB;
            opacity: 1;
            font-family: 'Roboto', sans-serif;
            text-transform: uppercase;
        }
    }

    .title-mobile-category {
        padding-top: 10px;
        margin: 0;
        font-size: 14px;
        text-align: left;
        font: Bold 24px/29px Roboto;
        letter-spacing: 0;
        color: black;
        opacity: 1;
        font-family: 'Roboto', sans-serif;
        text-transform: uppercase;
    }

    .delete-underline-on-hover:hover {
        text-decoration: none;
    }

    .title-mobile-catalogue {
        padding-top: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #04A4EB;
        margin-bottom: 5;
        font-size: 14px;
        text-align: left;
        font: Bold 24px/29px Roboto;
        letter-spacing: 0;
        color: black;
        margin: 0;
        opacity: 1;
        font-family: 'Roboto', sans-serif;
        text-transform: uppercase;
    }

    .search-box-filter-device {
        margin-top: 35px;
        width: 70%;
        height: 30px;
        padding: 10px;
        font-size: 14px;
        line-height: 30px;
        background-color: white;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: none;
        outline: none;
        border: 1px solid map-get($colors, primary-btns);
    }

    .container-search-btn-device {
        border-radius: 15px;
        margin-left: -4px;
        background-color: #ffffff;
        height: 30px;
        display: inline-block;
        vertical-align: bottom;
        min-width: 0px;
        width: 50px !important;

        .btn-search-device {
            border: 1px solid #707070;
            width: 100%;
            height: 30px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;
            background-color: #04A4EB;
            color: #4a4a4a;
            font-size: 11px;
            border: none;
            outline: none;
            background-image: url(../../../../images/navbar/icon_search.png);
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: 15px center;
        }
    }

    .position-center-mobile {
        text-align: center;
        color: black !important;
        width: calc(70% + 50px);
        display: inline-flex;
        justify-content: space-between;

        .share-btn-nav-mobile {
            display: inline-block;
            /*margin-right: 30px;*/
            @media screen and (max-width: 337px) {
                /*margin-right: 20px*/
            }

            .share-btn {
                cursor: pointer;
                text-align: center;
                line-height: 30px;
                width: 40px;
                height: 40px;
                color: #4a4a4a;
                background-color: #ffffff;
                background-size: 21px;
                background-repeat: no-repeat;
                background-position: 6px center;
                margin-right: 0;
                vertical-align: middle;
                display: inline-block;
                border: 1px solid #04A4EB;
                border-radius: 28px;
                opacity: 1;
                background-image: url(../../../../images/navbar/share-cat.png);
            }
        }

        .container-contacts-mobile {
            .contact-decoration {
                .contact-logo-text-mob {
                    font-family: OpenSans-Semibold;
                    text-align: left;
                    line-height: 30px;
                    width: 40px;
                    height: 40px;
                    color: #4a4a4a;
                    background-color: #ffffff;
                    background-image: url(../../../../images/navbar/email-cat.png);
                    background-size: 21px;
                    background-repeat: no-repeat;
                    background-position: 9px center;
                    margin-right: 0;
                    vertical-align: middle;
                    display: inline-block;
                    border: 1px solid #04A4EB;
                    border-radius: 28px;
                    opacity: 1;
                }
            }
        }
    }
}
